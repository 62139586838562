<template>
  <div>
    <ion-header>
      <ion-toolbar>
        <ion-title>Settings</ion-title>
        <ion-buttons slot="end">
            <ion-button @click="modalClose()">Done</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <div class="avatar-row" v-if="config.features == 'FULL'">
        <div
          class="avatar"
          :style="{
            backgroundColor: avatarColor,
            backgroundImage: `url(${profilePicture})`,
          }"
          @click="showProfilePictureActions()"
        >
          <ion-icon
            slot="icon-only"
            :ios="cameraOutline"
            :md="cameraOutline"
          ></ion-icon>
        </div>
      </div>
      <ion-list lines="full" class="ion-no-margin">
        <ion-item>
          <ion-label position="stacked">Name</ion-label>
          <ion-input v-model="profileName"></ion-input>
        </ion-item>

        <ion-list-header lines="full">
          <ion-label>
            Display Settings
          </ion-label>
        </ion-list-header>
        
        <ion-item v-if="config.features == 'FULL'">
          <ion-label>Light/dark mode</ion-label>
          <ion-select
            :value="state.theme"
            @ionChange="themeChange"
            interface="popover"
          >
            <ion-select-option value="match">Match device</ion-select-option>
            <ion-select-option value="light">Light</ion-select-option>
            <ion-select-option value="dark">Dark</ion-select-option>
          </ion-select>
        </ion-item>
        
        <ion-item>
          <ion-label>Input color</ion-label>
          <ion-select
            :value="state.inputColor"
            @ionChange="inputColorChange"
            interface="popover"
            :class="[`input-color-${state.inputColor}`]"
          >
            <ion-select-option
              v-for="[opt, name] in inputColorOptions"
              :value="opt"
              :key="opt"
              :class="[`input-color-${opt}`]"
            >
              {{ name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        
        <ion-item>
          <ion-label>Input font</ion-label>
          <ion-select
            :value="state.inputFont"
            @ionChange="inputFontChange"
            interface="popover"
            :class="[`input-font-${state.inputFont}`]"
          >
            <ion-select-option
              v-for="[opt, name] in inputFontOptions"
              :value="opt"
              :key="opt"
              :class="[`input-font-${opt}`]"
            >
              {{ name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        
        <ion-item>
          <ion-label>Highlight Areas and Numbers</ion-label>
          <ion-toggle
            @ionChange="inputShowHighlights"
            :checked="state.showHighlights"
          ></ion-toggle>
        </ion-item>

      </ion-list>

    </ion-content>
  </div>
</template>

<script>
import { 
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonInput,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonToggle,
  modalController 
} from '@ionic/vue';
import { cameraOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { Plugins, CameraResultType, CameraDirection, CameraSource } from '@capacitor/core';
import { stringToHslColor } from '@/utils';
import * as api from '@/api.ts';
import config from '@/config.ts';
const { Camera, Modals } = Plugins;

import { store } from '@/store';


export default defineComponent({
  name: 'SettingsModal',
  props: {
      
  },
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonInput,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonToggle,
  },   
  setup() {
    const avatarColor = ref('#ddd');
    const getAvatarColor = async () => {
      const playerId = await api.getPlayerId();
      avatarColor.value = stringToHslColor(playerId, 70, 80);
    }
    getAvatarColor();
    return {
      cameraOutline,
      avatarColor,
      config,
    }
  },
  data() {
    return {
      profileName: store.state.profileName,
      profilePicture: store.state.profilePicture,

      state: store.state,

      inputColorOptions: [
        ['default', 'Black'],
        ['grey', 'Grey'],
        ['red', 'Red'],
        ['blue', 'Blue'],
        ['pink', 'Pink'],
      ],

      inputFontOptions: [
        ['default', 'Default'],
        ['handwriting', 'Handwriting'],
      ],
    }
  },
  unmounted() {
    this.saveProfile();
    // TODO: emit an event so we can update profile during game
  },
  methods: {
    modalClose() {
      modalController.dismiss();
    },
    async saveProfile() {
      await store.setAndStore('profileName', this.profileName);
      await store.setAndStore('profilePicture', this.profilePicture);
    },
    async themeChange(event) {
      await store.setAndStore('theme', event.detail.value);
    },
    async inputFontChange(event) {
      await store.setAndStore('inputFont', event.detail.value);
    },
    async inputColorChange(event) {
      await store.setAndStore('inputColor', event.detail.value);
    },
    async inputShowHighlights(event) {
      await store.setAndStore('showHighlights', event.detail.checked);
    },
    async showProfilePictureActions() {
      if (config.features != 'FULL') return;
      let source;
      const options = [
        {
          title: 'Take Picture'
        },
        {
          title: 'From Photos'
        },
      ]
      if (this.profilePicture) {
        options.push({
          title: 'Remove Image',
          style: 'DESTRUCTIVE',
        })
      }
      options.push({
          title: 'Cancel',
          style: 'CANCEL',
      })
      const promptRet = await Modals.showActions({
        title: 'Set Profile Image',
        // message: 'Choose a profile image',
        options: options,
      })
      if (promptRet.index==2 && this.profilePicture) {
        this.profilePicture = '';
        return;
      } else if (promptRet.index==0) {
        source = CameraSource.Camera
      } else if (promptRet.index==1) {
        source = CameraSource.Photos
      } else {
        return;
      }
      const image = await Camera.getPhoto({
        quality: 80,
        allowEditing: true,
        resultType: CameraResultType.DataUrl,
        direction: CameraDirection.Front,
        source: source,
        width: 128,
        height: 128,
      });
      const dataUrl = image.dataUrl;
      this.profilePicture = dataUrl;
    },
  },
});
</script>

<style scoped>
  .avatar-row {
    text-align: center;
    padding: 10px;
  }
  .avatar {
    display: inline-flex;
    width: 92px;
    height: 92px;
    border-radius:  100%;
    background-color: #ddd;
    background-size: cover;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--ion-text-color, #000);
    box-shadow: -3px 3px 0 0 var(--ion-text-color, #000);
  }
  .avatar ion-icon {
    font-size: 32px;
    color: #fff;
  }
</style>