<template>
  <ion-button @click="openSettingsModal">
    <ion-icon slot="icon-only" :ios="settingsSharp" :md="settingsSharp"></ion-icon>
  </ion-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { settingsSharp } from 'ionicons/icons';
import { 
  IonButton,
  IonIcon,
  modalController,
 } from '@ionic/vue';

import SettingsModal from '@/views/Settings.vue';

const presentingElementNull: any = null;

export default defineComponent({
  name: 'SettingsButton',
  components: { IonButton, IonIcon },
  props: {
    presentingElement: presentingElementNull,
  },

  setup() {
    return {
      settingsSharp,
    }
  },

  methods: {
    async openSettingsModal() {
      let presentingElement = this.$parent ? this.$parent.$el : this.$el;
      if (this.presentingElement){
        presentingElement = this.presentingElement;
      }
      const modal = await modalController
        .create({
          component: SettingsModal,
          swipeToClose: true,
          presentingElement: presentingElement,
          cssClass: 'settings-modal',
        })
      return modal.present();
    },
  },
})
</script>

<style scoped>

</style>