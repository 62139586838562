
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { 
  homeOutline,
  home,
  fileTrayFullOutline,
  fileTrayFull,
 } from 'ionicons/icons';

export default defineComponent({
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  data() {
    return {
      activeTab: String,
      iconHome: homeOutline,
      iconHomeActive: home,
      iconPastGames: fileTrayFullOutline,
      iconPastGamesActive: fileTrayFull,
    }
  },
  methods: {
    tabChange(event: any) {
      this.activeTab = event.tab;
    }
  }
})
