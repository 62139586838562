
import { defineComponent } from 'vue';
import * as api from '@/api.ts';
import GridCell from './GridCell.vue';

export default defineComponent({
  name: 'Grid',
  emits: ['cell-click'],
  props: {
    game: api.Game,
    enabled: Boolean,
  },
  components: {
    GridCell,
  },
  methods: {
    cellClicked(col: number, row: number) {
      this.$emit('cell-click', {col, row})
    },
  }
})
