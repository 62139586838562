import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { Plugins } from '@capacitor/core';
const { App } = Plugins;

import MainTabs from '../views/MainTabs.vue'
import Home from '../views/Home.vue'
import Game from '../views/Game.vue'
import PastGames from '../views/PastGames.vue'
import * as api from '@/api.ts';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: MainTabs,
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        component: Home
      },
      {
        path: 'past-games',
        component: PastGames
      },
    ]
  },
  {
    path: '/game/:gameId',
    name: 'Game',
    component: Game
  },
  {
    path: '/join/:gameCode',
    redirect: to => {
      api.joinGameWithCode(to.params.gameCode as string);
      return '/';
    }
  },
  {
    path: '/scan-grid',
    component: () => import('@/views/ScanGrid.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

App.addListener('appUrlOpen', function (data) {
  // Example url: https://www.playsudokutoo.com/joingame/qweqwe
  // slug = /joingame/qweqwe
  const slug = data.url.split('.com').pop();
  if (slug) {
    router.push({
      path: slug,
    });
  }
});

export default router
