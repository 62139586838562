
import { defineComponent } from 'vue';
import { popoverController, IonList, IonItem, IonIcon } from '@ionic/vue';

export default defineComponent({
  name: 'PopoverActions',
  components: {
    IonList,
    IonItem,
    IonIcon,
  },
  props: {
    buttons: Array,
  },
  setup() {
    return {
      popoverController,
    }
  },
})
