<template>
  <ion-list>
    <ion-item
      button
      detail="false"
      v-for="(button, index) of buttons"
      :key="index"
      :lines="(buttons.length == index+1) ? 'none' : 'full'"
      :class="[`roll-${button.roll}`]"
      @click="button.handler"
    >
      {{ button.text }}
      <ion-icon
        v-if="button.icon"
        slot="end"
        size="small"
        :icon="button.icon"
      ></ion-icon>
    </ion-item>
  </ion-list>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { popoverController, IonList, IonItem, IonIcon } from '@ionic/vue';

export default defineComponent({
  name: 'PopoverActions',
  components: {
    IonList,
    IonItem,
    IonIcon,
  },
  props: {
    buttons: Array,
  },
  setup() {
    return {
      popoverController,
    }
  },
})
</script>

<style scoped>
  .roll-destructive {
    --color: var(--ion-color-danger);
  }
</style>