
import { 
  IonContent,
  IonHeader,
  IonPage,
  IonBackButton, 
  IonButtons, 
  IonIcon,
  IonTitle, 
  IonToolbar,
  IonButton,
  IonLabel,
  IonToggle,
  IonList,
  IonItem,
  IonInput,
  toastController,
} from '@ionic/vue';

import { 
  arrowBack,
  arrowForward,
  close,
  bug,
  cloudDone,
  cloudOffline,
  copy,
  share,
  shareSocial,
} from 'ionicons/icons';

import { useRoute } from 'vue-router';
import { defineComponent } from 'vue';
import { Plugins } from '@capacitor/core';
const { Share, Clipboard } = Plugins;

import * as api from '@/api.ts';
import { store } from '@/store';
import Grid from '@/components/Grid.vue';
import NumPad from '@/components/NumPad.vue';
import SettingsButton from '@/components/SettingsButton.vue';
import Players from '@/components/Players.vue';
import { alert, prompt } from '@/utils/dialogs';

export default defineComponent({
  name: 'Game',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonBackButton, 
    IonButtons, 
    IonButton,
    IonIcon,
    IonTitle, 
    IonToolbar,
    IonLabel,
    IonToggle,
    IonList,
    IonItem,
    IonInput,
    Grid,
    NumPad,
    SettingsButton,
    Players,
  },

  setup() {
    const route = useRoute();
    const { gameId } = route.params;
    return {
      arrowBack,
      arrowForward,
      close,
      bug,
      cloudDone,
      cloudOffline,
      copy,
      share,
      shareSocial,
      gameId,
    }
  },
  
  created() {
    this.loadGame();
    this.checkUsername();
    document.addEventListener('keyup', this.keyupListener);
    window.addEventListener('resize', this.resizeListener);
    this.resizeListener();
  },

  unmounted() {
    if (this.game) {
      this.game.disconnect();
    }
    this.stopTick();
    document.removeEventListener('keyup', this.keyupListener);
    window.removeEventListener('resize', this.resizeListener);
  },

  ionViewDidEnter() {
    this.game.unpauseTimer()
  },

  ionViewDidLeave() {
    this.game.pauseTimer()
  },

  data() {
    const ticker: any = 0;
    return {
      game: new api.Game({}),
      gameLoaded: false,
      ticker: ticker,
      countdown: 3000,
      countdownStarted: false,
      notesOn: false,
      showDebug: false,
      timer: '0:00',
      state: store.state,
      boardSize: 0,
    }
  },

  methods: {

    async loadGame() {
      const game = await api.getGame(this.gameId as string);
      this.game = game;
      this.gameLoaded = true;
      this.game.connect()
      this.startTick()
      if (game.state.offlineGame && !game.state.startTime) {
        game.start();
      }
    },

    async checkUsername() {
      if (this.profileName == 'Unnamed') {
        const ret = await prompt({
          title: 'Enter Name',
          message: 'Please enter your name',
        })
        if (!ret.cancelled) {
          store.setAndStore('profileName', ret.value);
        }
      }
    },

    async shareGameCode() {
      try {
        const shareRet = await Share.share({
          title: 'Play Sudoku with me.',
          text: 'Play Sudoku with me.',
          url: this.game.state.inviteUrl,
          dialogTitle: 'Share Game'
        });
        console.log(shareRet);
      } catch(e) {
        console.log('Share canceled', e);
      }
    },

    copyValue(value: string, name: string) {
      Clipboard.write({
        string: value,
      });
      this.toast({
          message: `${name} copied to clipboard.`,
          duration: 1500,
          color: 'dark',
      });
    },
    
    hilightTextClick(event: any){
      const input = event.target;
      input.setSelectionRange(0, input.value.length)
    },

    startGame(){
      this.game.start();
    },

    startTick(){
      if (!this.ticker) {
        this.tick()
        this.ticker = setInterval(() => {
          this.tick()
        }, 100);
      }
    },

    stopTick(){
      if (this.ticker) {
        clearInterval(this.ticker);
      }
    },

    tick(){
      // Do countdown at start
      if (this.game.state.status == 'active' && this.countdown > 0) {
        const countdown = Math.max(this.game.state.startTime - this.game.time(), 0);
        if (countdown < this.countdown){
          this.countdown = countdown;
        }
        if (!this.countdownStarted) {
          this.game.initGrid();
          this.countdownStarted = true;
        }
      }
      // Do timer
      if (this.game.state.status == 'active') {
        const duration = this.game.getClockTime()/1000;
        const hrs = Math.floor(duration / 3600);
        const mins = Math.floor((duration % 3600) / 60);
        const secs = Math.floor(duration) % 60;
        // Output like "1:01" or "4:03:59" or "123:03:59"
        let timer = "";
        if (hrs > 0) {
            timer += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        timer += "" + mins + ":" + (secs < 10 ? "0" : "");
        timer += "" + secs;
        this.timer = timer;
      }
    },

    cellClick({col, row}: {col: number; row: number}){
      this.game.state.selected = {
        col: col,
        row: row,
      }
      this.game.saveState();
    },

    updateProgress(){
      const [progressAttempt, complete] = this.game.updateProgress();
      if ((progressAttempt == 81) && !complete) {
        alert({
          title: 'Incorect Solution',
          message: 'The grid does not validate.',
        });
      }
    },

    numClick({num}: {num: number}){
      if (!this.game.state.selected) return;
      if (this.complete) return;
      const row: number = this.game.state.selected.row;
      const col: number = this.game.state.selected.col;
      const cell = this.game.state.grid[row][col];
      if (cell.preset) {
        // TODO: error message? wobble?
      } else if (this.notesOn) {
        if (cell.notes.includes(num)) {
          const index = cell.notes.indexOf(num);
          cell.notes.splice(index, 1);
        } else {
          cell.notes.push(num);
        }
        this.game.pushUndoStack();
        this.game.saveState();
      } else {
        if (cell.number == num){
          cell.number = null;
        } else {
          cell.number = num;
        }
        this.game.pushUndoStack();
        this.game.saveState();
        this.updateProgress();
      }
    },

    eraseClick(){
      if (!this.game.state.selected) return;
      if (this.complete) return;
      const row: number = this.game.state.selected.row;
      const col: number = this.game.state.selected.col;
      const cell = this.game.state.grid[row][col];
      if (cell.preset) {
        // TODO: error message? wobble?
      } else if (this.notesOn) {
        if (cell.notes.length > 0) {
          cell.notes = [];
          this.game.pushUndoStack();
          this.game.saveState();
        }
      } else {
        if (cell.number) {
          cell.number = null;
          this.game.pushUndoStack();
          this.game.saveState();
          this.updateProgress();
        }
      }
    },

    undo(){
      if (this.canUndo) {
        this.game.undo();
        this.game.saveState();
        this.updateProgress();
      }
    },

    redo(){
      if (this.canRedo) {
        this.game.redo();
        this.game.saveState();
        this.updateProgress();
      }
    },

    move(dir: string) {
      if (!this.game.state.selected) {
        this.game.state.selected = {
          row: 0,
          col: 0,
        }
        return;
      }
      const selected = this.game.state.selected;
      if (dir=='UP') {
        let newRow = selected.row-1;
        if (newRow < 0) {
          newRow = 8;
        }
        selected.row = newRow;
      } else if (dir=='DOWN') {
        let newRow = selected.row+1;
        if (newRow > 8) {
          newRow = 0;
        }
        selected.row = newRow;
      } else if (dir=='RIGHT') {
        let newCol = selected.col+1;
        if (newCol > 8) {
          newCol = 0;
        }
        selected.col = newCol;
      } else if (dir=='LEFT') {
        let newCol = selected.col-1;
        if (newCol < 0) {
          newCol = 8;
        }
        selected.col = newCol;
      }
    },

    keyupListener(event: any){
      event.preventDefault();
      if (event.keyCode==38 || event.keyCode==87) { // UP or 'w'
        this.move('UP')
      } else if (event.keyCode==40 || event.keyCode==83) { // DOWN or 's'
        this.move('DOWN')
      } else if (event.keyCode==39 || event.keyCode==68) { // RIGHT or 'd'
        this.move('RIGHT')
      } else if (event.keyCode==37 || event.keyCode==65) { // LEFT or 'a'
        this.move('LEFT')
      } else if (event.keyCode==8 || event.keyCode==46) { // Backspace or Delete
        this.eraseClick()
      } else if (event.keyCode==78 && !this.complete) { // 'n' for toggle notes
        this.notesOn = !this.notesOn;
      } else if (
          (event.keyCode==89 && (event.ctrlKey || event.metaKey)) ||
          (event.keyCode==90 && event.shiftKey && (event.ctrlKey || event.metaKey))
          ) { // 'z'+CTRL for undo
        this.redo();
      } else if (event.keyCode==90 && (event.ctrlKey || event.metaKey)) { // 'z'+CTRL for undo
        this.undo();
      } else if (event.keyCode>=49 && event.keyCode<=57) { // Number 1-9
        const number = event.keyCode-48;
        this.numClick({num: number});
      }
    },

    resizeListener(){
      // height: min(95vw, 55vh);
      this.boardSize = Math.min(
        window.innerHeight * 0.55,
        window.innerWidth * 0.95
      )
    },

    async toast(options: any) {
      const toast = await toastController
        .create(options)
      return toast.present();
    },
  },
  
  computed: {
    profileName: function(){ return store.state.profileName },
    profilePicture: function(){ return store.state.profilePicture },
    isHost() {
      const game = this.game as any;
      if (!this.gameLoaded) return false;
      return game.state.hostId == game.state.playerId
    },
    canUndo(){
      if (this.complete) return false;
      const game = this.game as any;
      if (!this.gameLoaded) return false;
      return game.state.undoStackCursor > 0;
    },
    canRedo(){
      if (this.complete) return false;
      const game = this.game as any;
      if (!this.gameLoaded) return false;
      return (game.state.undoStackCursor+1) < game.state.undoStack.length;
    },
    canErase(){
      if (this.complete) return false;
      const game = this.game as any;
      if (!this.gameLoaded) return false;
      if (!game.state.selected) return false;
      const row: number = game.state.selected.row;
      const col: number = game.state.selected.col;
      const cell = game.state.grid[row][col];
      if (cell.preset) {
        return false;
      } else if (this.notesOn) {
        if (cell.notes.length == 0) {
          return false;
        }
      } else {
        if (!cell.number) {
          return false;
        }
      }
      return true;
    },
    complete(){
      const game = this.game as any;
      return game.state.progress == 81;
    }
  },

  watch: {
    profileName: function() {
      this.game.sendProfileName('ALL')
    },
    profilePicture: function() {
      this.game.sendProfilePicture('ALL')
    },
  }
});
