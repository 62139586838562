<template>
  <div 
    class="sudoku-grid-cell" 
    :class="[
      (notpreset ? inputFontClass : ''),
      (notpreset ? inputColorClass : ''),
      {
        selected,
        preset,
        notpreset,
        'align-selected': alignSelected,
      }
    ]"
  >
    <div
      class="main-number"
      :class="{
        'match-selected-number': matchSelectedNumber,
      }"
      v-if="number"
    >
      <span>{{ number }}</span>
    </div>
    <div class="notes" v-if="!game.state.grid[row][col].number">
      <div class="notesRow" v-for="noteRow in 3" :key="noteRow">
        <div class="notesCol" v-for="noteCol in 3" :key="noteCol">
          <span 
            v-if="game.state.grid[row][col].notes.includes(((noteRow-1)*3)+noteCol)"
          >{{ ((noteRow-1)*3)+noteCol }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as api from '@/api.ts';
import { store } from '@/store';

export default defineComponent({
  name: 'GridCell',
  props: {
    game: api.Game,
    row: Number,
    col: Number,
    enabled: Boolean,
  },
  computed: {
    showHighlights() {
      return store.state.showHighlights
    },
    inputFont() {
      return store.state.inputFont
    },
    inputFontClass() {
      return `input-font-${this.inputFont}`
    },
    inputColor() {
      return store.state.inputColor
    },
    inputColorClass() {
      return `input-color-${this.inputColor}`
    },
    selected() {
      const game: any = this.game;
      const selected = game.state.selected;
      if (selected != undefined){
        if ((selected.row == this.row) && (selected.col == this.col)) {
          return true
        }
      }
      return false;
    },
    alignSelected() {
      if (!this.showHighlights) return false;
      const game: any = this.game;
      const selected = game.state.selected;
      const row = this.row as any;
      const col = this.col as any;
      if (selected != undefined){
        if ((selected.row == row) || (selected.col == col)) {
          return true
        }
        if ((~~(selected.row/3) == ~~(row/3)) && (~~(selected.col/3) == ~~(col/3))) {
          return true
        }
      }
      return false;
    },
    selectedValue(): (number | null) {
      const game: any = this.game;
      const selected = game.state.selected;
      if (this.game && selected != undefined){
        return this.game.state.grid[selected.row][selected.col].number
      }
      return null
    },
    matchSelectedNumber(): boolean {
      if (!this.showHighlights) return false;
      return this.number == this.selectedValue;
    },
    number(): (number | null) {
      const game: any = this.game;
      return game.state.grid[this.row as number][this.col as number].number || null;
    },
    preset() {
      const game: any = this.game;
      if (this.row == undefined || this.col == undefined) return false;
      if (game.state.grid[this.row][this.col].preset) return true;
      return false
    },
    notpreset() {
      return !this.preset;
    },
    // notes(){
    //   const game: any = this.game;
    //   notes = 
    // }
  }
})
</script>

<style scoped>

  .sudoku-grid-cell {
    position: relative;
    cursor: pointer;
    font-size: min(8vw, 40px);
  }

  @media (hover: hover) and (pointer: fine) {
    .sudoku-grid-cell:hover {
      background: var(--ion-color-light, #f4f5f8);
    }
  }

  .sudoku-grid-cell.align-selected {
    /*background: var(--ion-color-light);*/
    background: rgba(var(--ion-color-success-rgb), 0.1);
  }

  .sudoku-grid-cell.selected {
    background: var(--ion-color-warning);
    /*background: rgba(var(--ion-color-secondary-rgb), 0.5);*/
  }

  .main-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .main-number span {
      display: block;
  }

  .main-number.match-selected-number {
    font-weight: 800;
  }

  .notes {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    height: 100%;
    font-size: min(3.5vw, 19px);
  }

  .notesRow {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: stretch;
    align-content: stretch;
    height: calc(100%/3);
  }

  .notesCol {
    flex-grow: 1;
    width: calc(100%/9);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

</style>