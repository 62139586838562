<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/home"></ion-back-button>
          <!--ion-button @click="showDebug = !showDebug;">
            <ion-icon slot="icon-only" :ios="bug" :md="bug"></ion-icon>
          </ion-button-->
        </ion-buttons>
        <ion-title>SudokuToo</ion-title>
        <ion-buttons slot="primary">
          <ion-button disabled color="medium" v-if="!game.state.offlineGame && game.connected">
            <ion-icon slot="icon-only" :ios="cloudDone" :md="cloudDone"></ion-icon>
          </ion-button>
          <ion-button disabled color="danger" v-if="!game.state.offlineGame && !game.connected">
            <ion-icon slot="icon-only" :ios="cloudOffline" :md="cloudOffline"></ion-icon>
          </ion-button>
          <settings-button :presentingElement="$parent.$el"></settings-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div id="game" v-if="gameLoaded">

        <div id="status">
          <div class="level">
            {{ game.getLevel() }}
          </div>
          <div class="alerts">
          
          </div>
          <div class="timer">
            <span
              v-for="(char, i) in timer"
              :key="i"
              :class="{num: (!isNaN(char))}"
            >{{ char }}</span><br>
          </div>
        </div>

        <div id="players">
          <players :game="game"></players>
        </div>

        <div id="middle">
          <div id="board" :class="{flip: (game.state.status == 'active') && (countdown == 0)}" :style="{'width': boardSize+'px', 'height': boardSize+'px',}">

            <div id="board-invite" v-if="game.state.status == 'open'">
              <div>
                <ion-button @click="shareGameCode" expand="full" class="shareButton">
                  <ion-icon slot="start" :ios="share" :md="shareSocial"></ion-icon>
                  Invite Players
                </ion-button>
                <ion-list lines="full" class="ion-no-margin">
                  <ion-item>
                    <ion-label position="stacked">Game Code</ion-label>
                    <ion-input 
                      :value="game.state.gameCode"
                      @click="hilightTextClick"
                      readonly
                    ></ion-input>
                    <ion-button 
                      fill="clear"
                      slot="end"
                      @click.stop.prevent="copyValue(game.state.gameCode, 'Game code')"
                    >
                      <ion-icon :ios="copy" :md="copy"></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item>
                    <ion-label position="stacked">Invite Link</ion-label>
                    <ion-input
                      :value="game.state.inviteUrl"
                      @click="hilightTextClick"
                      readonly
                    ></ion-input>
                    <ion-button 
                      fill="clear"
                      slot="end"
                      @click.stop.prevent="copyValue(game.state.inviteUrl, 'Invite link')"
                    >
                      <ion-icon :ios="copy" :md="copy"></ion-icon>
                    </ion-button>
                  </ion-item>
                </ion-list>
              </div>
            </div>

            <div id="board-countdown" v-if="game.state.status == 'active'">
              <transition name="countdown-fade">
                <div
                  :key="Math.ceil(countdown/1000)"
                >
                  <span>{{ Math.ceil(countdown/1000) }}</span>
                </div>
              </transition>
            </div>

            <div id="board-game" v-if="(game.state.status == 'active') && (countdown == 0)">
              <Grid
                :game="game"
                @cell-click="cellClick"
                :enabled="!complete"
              ></Grid>
            </div>

          </div>
          <transition name="countdown-fade">
            <div class="complete-message" v-if="complete"></div>
          </transition>
        </div>


        <div id="controlls">

          <transition name="start-game-fade">
            <div class="start-game" v-if="game.state.status == 'open'">
              <ion-button v-if="isHost" @click="startGame" size="large" color="success">Start Game</ion-button>
              <div v-if="!isHost" class="waiting-host-start-msg">Waiting for the host to start the&nbsp;game...</div>
            </div>
          </transition>

          <transition name="grid-tools-fade">
            <div class="grid-tools" v-if="(game.state.status == 'active') && (countdown == 0)">
              <div class="left">
                <ion-button color="light" @click="undo" :disabled="!canUndo" class="undo-button">
                  <ion-icon slot="icon-only" :ios="arrowBack" :md="arrowBack"></ion-icon>
                </ion-button>
                <ion-button color="light" @click="redo" :disabled="!canRedo" class="redo-button">
                  <ion-icon slot="icon-only" :ios="arrowForward" :md="arrowForward"></ion-icon>
                </ion-button>
                <ion-button color="light" @click="eraseClick" :disabled="!canErase" class="erase-button">
                  <ion-icon slot="icon-only" :ios="close" :md="close"></ion-icon>
                </ion-button>
              </div>
              <div class="right">
                <ion-label>Notes&nbsp;</ion-label>
                <ion-toggle
                  color="primary"
                  :checked="notesOn"
                  :disabled="complete"
                  @ionChange="notesOn = $event.target.checked;"
                ></ion-toggle>
              </div>
            </div>
          </transition>
          <transition name="grid-tools-fade">
            <NumPad 
              @num-click="numClick"
              v-if="(game.state.status == 'active') && (countdown == 0)"
              :disabled="complete"
            ></NumPad>
          </transition>

        </div>

      </div>

      <div id="keyboard-shortcuts">
        <b>Keyboard Shortcuts:</b> 
        <i>←</i><span class="stacked"><i>↑</i><i>↓</i></span><i>→</i> to select a cell,
        <i class="wide">ctrl</i>+<i>z</i> for undo, 
        <i>⌫</i> to clear a cell,
        <i>n</i> to toggle notes
      </div>

      <div id="debug" v-if="showDebug">
        <p><b>Debug</b></p>
        <p>
          {{ game.state.gameId }}<br>
          Game Code: {{ game.state.gameCode }}<br>
          Created: {{ new Date(game.state.created) }}<br>
          Expires: {{ new Date(game.state.expires) }}<br>
          Connected: {{ game.connected }}<br>
          Status: {{ game.state.status }}<br>
          Time Offset: {{ game.timeOffset }}<br>
          Known: {{ game.state.known }}
        </p>
        <p><b>Players:</b></p>
        <ol>
          <li v-for="player in game.state.player" :key="player.playerId">
            {{ player.playerId }}<br>
            {{ player.name }}<br>
            Connected: {{ player.connected }}<br>
            Progress: {{ player.progress }}<br>
            Last Move: {{ player.lastMove }}
          </li>
        </ol>
      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonContent,
  IonHeader,
  IonPage,
  IonBackButton, 
  IonButtons, 
  IonIcon,
  IonTitle, 
  IonToolbar,
  IonButton,
  IonLabel,
  IonToggle,
  IonList,
  IonItem,
  IonInput,
  toastController,
} from '@ionic/vue';

import { 
  arrowBack,
  arrowForward,
  close,
  bug,
  cloudDone,
  cloudOffline,
  copy,
  share,
  shareSocial,
} from 'ionicons/icons';

import { useRoute } from 'vue-router';
import { defineComponent } from 'vue';
import { Plugins } from '@capacitor/core';
const { Share, Clipboard } = Plugins;

import * as api from '@/api.ts';
import { store } from '@/store';
import Grid from '@/components/Grid.vue';
import NumPad from '@/components/NumPad.vue';
import SettingsButton from '@/components/SettingsButton.vue';
import Players from '@/components/Players.vue';
import { alert, prompt } from '@/utils/dialogs';

export default defineComponent({
  name: 'Game',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonBackButton, 
    IonButtons, 
    IonButton,
    IonIcon,
    IonTitle, 
    IonToolbar,
    IonLabel,
    IonToggle,
    IonList,
    IonItem,
    IonInput,
    Grid,
    NumPad,
    SettingsButton,
    Players,
  },

  setup() {
    const route = useRoute();
    const { gameId } = route.params;
    return {
      arrowBack,
      arrowForward,
      close,
      bug,
      cloudDone,
      cloudOffline,
      copy,
      share,
      shareSocial,
      gameId,
    }
  },
  
  created() {
    this.loadGame();
    this.checkUsername();
    document.addEventListener('keyup', this.keyupListener);
    window.addEventListener('resize', this.resizeListener);
    this.resizeListener();
  },

  unmounted() {
    if (this.game) {
      this.game.disconnect();
    }
    this.stopTick();
    document.removeEventListener('keyup', this.keyupListener);
    window.removeEventListener('resize', this.resizeListener);
  },

  ionViewDidEnter() {
    this.game.unpauseTimer()
  },

  ionViewDidLeave() {
    this.game.pauseTimer()
  },

  data() {
    const ticker: any = 0;
    return {
      game: new api.Game({}),
      gameLoaded: false,
      ticker: ticker,
      countdown: 3000,
      countdownStarted: false,
      notesOn: false,
      showDebug: false,
      timer: '0:00',
      state: store.state,
      boardSize: 0,
    }
  },

  methods: {

    async loadGame() {
      const game = await api.getGame(this.gameId as string);
      this.game = game;
      this.gameLoaded = true;
      this.game.connect()
      this.startTick()
      if (game.state.offlineGame && !game.state.startTime) {
        game.start();
      }
    },

    async checkUsername() {
      if (this.profileName == 'Unnamed') {
        const ret = await prompt({
          title: 'Enter Name',
          message: 'Please enter your name',
        })
        if (!ret.cancelled) {
          store.setAndStore('profileName', ret.value);
        }
      }
    },

    async shareGameCode() {
      try {
        const shareRet = await Share.share({
          title: 'Play Sudoku with me.',
          text: 'Play Sudoku with me.',
          url: this.game.state.inviteUrl,
          dialogTitle: 'Share Game'
        });
        console.log(shareRet);
      } catch(e) {
        console.log('Share canceled', e);
      }
    },

    copyValue(value: string, name: string) {
      Clipboard.write({
        string: value,
      });
      this.toast({
          message: `${name} copied to clipboard.`,
          duration: 1500,
          color: 'dark',
      });
    },
    
    hilightTextClick(event: any){
      const input = event.target;
      input.setSelectionRange(0, input.value.length)
    },

    startGame(){
      this.game.start();
    },

    startTick(){
      if (!this.ticker) {
        this.tick()
        this.ticker = setInterval(() => {
          this.tick()
        }, 100);
      }
    },

    stopTick(){
      if (this.ticker) {
        clearInterval(this.ticker);
      }
    },

    tick(){
      // Do countdown at start
      if (this.game.state.status == 'active' && this.countdown > 0) {
        const countdown = Math.max(this.game.state.startTime - this.game.time(), 0);
        if (countdown < this.countdown){
          this.countdown = countdown;
        }
        if (!this.countdownStarted) {
          this.game.initGrid();
          this.countdownStarted = true;
        }
      }
      // Do timer
      if (this.game.state.status == 'active') {
        const duration = this.game.getClockTime()/1000;
        const hrs = Math.floor(duration / 3600);
        const mins = Math.floor((duration % 3600) / 60);
        const secs = Math.floor(duration) % 60;
        // Output like "1:01" or "4:03:59" or "123:03:59"
        let timer = "";
        if (hrs > 0) {
            timer += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        timer += "" + mins + ":" + (secs < 10 ? "0" : "");
        timer += "" + secs;
        this.timer = timer;
      }
    },

    cellClick({col, row}: {col: number; row: number}){
      this.game.state.selected = {
        col: col,
        row: row,
      }
      this.game.saveState();
    },

    updateProgress(){
      const [progressAttempt, complete] = this.game.updateProgress();
      if ((progressAttempt == 81) && !complete) {
        alert({
          title: 'Incorect Solution',
          message: 'The grid does not validate.',
        });
      }
    },

    numClick({num}: {num: number}){
      if (!this.game.state.selected) return;
      if (this.complete) return;
      const row: number = this.game.state.selected.row;
      const col: number = this.game.state.selected.col;
      const cell = this.game.state.grid[row][col];
      if (cell.preset) {
        // TODO: error message? wobble?
      } else if (this.notesOn) {
        if (cell.notes.includes(num)) {
          const index = cell.notes.indexOf(num);
          cell.notes.splice(index, 1);
        } else {
          cell.notes.push(num);
        }
        this.game.pushUndoStack();
        this.game.saveState();
      } else {
        if (cell.number == num){
          cell.number = null;
        } else {
          cell.number = num;
        }
        this.game.pushUndoStack();
        this.game.saveState();
        this.updateProgress();
      }
    },

    eraseClick(){
      if (!this.game.state.selected) return;
      if (this.complete) return;
      const row: number = this.game.state.selected.row;
      const col: number = this.game.state.selected.col;
      const cell = this.game.state.grid[row][col];
      if (cell.preset) {
        // TODO: error message? wobble?
      } else if (this.notesOn) {
        if (cell.notes.length > 0) {
          cell.notes = [];
          this.game.pushUndoStack();
          this.game.saveState();
        }
      } else {
        if (cell.number) {
          cell.number = null;
          this.game.pushUndoStack();
          this.game.saveState();
          this.updateProgress();
        }
      }
    },

    undo(){
      if (this.canUndo) {
        this.game.undo();
        this.game.saveState();
        this.updateProgress();
      }
    },

    redo(){
      if (this.canRedo) {
        this.game.redo();
        this.game.saveState();
        this.updateProgress();
      }
    },

    move(dir: string) {
      if (!this.game.state.selected) {
        this.game.state.selected = {
          row: 0,
          col: 0,
        }
        return;
      }
      const selected = this.game.state.selected;
      if (dir=='UP') {
        let newRow = selected.row-1;
        if (newRow < 0) {
          newRow = 8;
        }
        selected.row = newRow;
      } else if (dir=='DOWN') {
        let newRow = selected.row+1;
        if (newRow > 8) {
          newRow = 0;
        }
        selected.row = newRow;
      } else if (dir=='RIGHT') {
        let newCol = selected.col+1;
        if (newCol > 8) {
          newCol = 0;
        }
        selected.col = newCol;
      } else if (dir=='LEFT') {
        let newCol = selected.col-1;
        if (newCol < 0) {
          newCol = 8;
        }
        selected.col = newCol;
      }
    },

    keyupListener(event: any){
      event.preventDefault();
      if (event.keyCode==38 || event.keyCode==87) { // UP or 'w'
        this.move('UP')
      } else if (event.keyCode==40 || event.keyCode==83) { // DOWN or 's'
        this.move('DOWN')
      } else if (event.keyCode==39 || event.keyCode==68) { // RIGHT or 'd'
        this.move('RIGHT')
      } else if (event.keyCode==37 || event.keyCode==65) { // LEFT or 'a'
        this.move('LEFT')
      } else if (event.keyCode==8 || event.keyCode==46) { // Backspace or Delete
        this.eraseClick()
      } else if (event.keyCode==78 && !this.complete) { // 'n' for toggle notes
        this.notesOn = !this.notesOn;
      } else if (
          (event.keyCode==89 && (event.ctrlKey || event.metaKey)) ||
          (event.keyCode==90 && event.shiftKey && (event.ctrlKey || event.metaKey))
          ) { // 'z'+CTRL for undo
        this.redo();
      } else if (event.keyCode==90 && (event.ctrlKey || event.metaKey)) { // 'z'+CTRL for undo
        this.undo();
      } else if (event.keyCode>=49 && event.keyCode<=57) { // Number 1-9
        const number = event.keyCode-48;
        this.numClick({num: number});
      }
    },

    resizeListener(){
      // height: min(95vw, 55vh);
      this.boardSize = Math.min(
        window.innerHeight * 0.55,
        window.innerWidth * 0.95
      )
    },

    async toast(options: any) {
      const toast = await toastController
        .create(options)
      return toast.present();
    },
  },
  
  computed: {
    profileName: function(){ return store.state.profileName },
    profilePicture: function(){ return store.state.profilePicture },
    isHost() {
      const game = this.game as any;
      if (!this.gameLoaded) return false;
      return game.state.hostId == game.state.playerId
    },
    canUndo(){
      if (this.complete) return false;
      const game = this.game as any;
      if (!this.gameLoaded) return false;
      return game.state.undoStackCursor > 0;
    },
    canRedo(){
      if (this.complete) return false;
      const game = this.game as any;
      if (!this.gameLoaded) return false;
      return (game.state.undoStackCursor+1) < game.state.undoStack.length;
    },
    canErase(){
      if (this.complete) return false;
      const game = this.game as any;
      if (!this.gameLoaded) return false;
      if (!game.state.selected) return false;
      const row: number = game.state.selected.row;
      const col: number = game.state.selected.col;
      const cell = game.state.grid[row][col];
      if (cell.preset) {
        return false;
      } else if (this.notesOn) {
        if (cell.notes.length == 0) {
          return false;
        }
      } else {
        if (!cell.number) {
          return false;
        }
      }
      return true;
    },
    complete(){
      const game = this.game as any;
      return game.state.progress == 81;
    }
  },

  watch: {
    profileName: function() {
      this.game.sendProfileName('ALL')
    },
    profilePicture: function() {
      this.game.sendProfilePicture('ALL')
    },
  }
});
</script>

<style scoped>

  ion-content {
    --overflow: hidden;
  }

  #game {
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: stretch;
    align-content: stretch;
    flex-direction: column;
  }
  
  #status {
    flex-grow: 0;
    font-size: 10px;
    height: 12px;
    margin: 2px 4px 0 4px;
    position: relative;
  }

  #status .level {
    position: absolute;
    left: 0;
    width: 30%;
    text-align: left;
    color: var(--ion-color-medium);
  }

  #status .alerts {
    text-align: center;
  }

  #status .timer {
    position: absolute;
    right: 0;
    width: 30%;
    text-align: right;
    color: var(--ion-color-medium);
  }

  #status .timer > span {
    display: inline-block;
    text-align: center;
  }

  #status .timer > .num {
    width: 0.65em;
  }

  #players {
    flex-grow: 1;
    max-height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index:8;
  }

  #middle {
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
    perspective: 1000px;
    position: relative;
    z-index: 10;
  }

  #controlls {
    flex-grow: 1;
    max-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 25%;
    position: relative;
    z-index: 6;
  }

  #board {
    width: min(95vw, 55vh);
    height: min(95vw, 55vh);
    max-width: 500px;
    max-height: 500px;
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /*box-shadow: 0px 3px 15px -6px rgba(0,0,0,0.3);*/
    /*box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);*/
    box-shadow: -3px 3px 0 0 var(--ion-text-color, #000);
    border-radius: 8px;
  }

  #board.flip {
    transform: rotateY(180deg);
    box-shadow: 3px 3px 0 0 var(--ion-text-color, #000);
  }

  #board-invite,
  #board-countdown,
  #board-game {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    background: var(--ion-background-color, #fff);
    border-radius: 8px;
  }

  #board-invite,
  #board-countdown{
    border: 1px solid var(--ion-text-color, #000);
    z-index: 2;
    border-radius: 8px;
  }

  #board-invite {
    display: flex;
    flex-direction:  column;
    justify-content: center;
  }

  #board-invite > div {
    padding: 0 10px 10px 10px;
  }

  #board-invite .shareButton {
    margin: 0;
  }

  #board-countdown{
    text-align: center;
    font-size: min(50vw, 300px);
    font-weight: bold;
    color: var(--ion-color-warning)
  }

  #board-countdown > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #board-countdown > div  > span {
    display: block;
  }

  #board-game {
    transform: rotateY(180deg);
  }

  .complete-message {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: min(13.5vw, 80px);
    font-family: 'Bungee';
  }
  .complete-message::before {
    display: block;
    content: "Complete!";
    position: absolute;
    font-family: 'Bungee';
    color: var(--ion-color-success);
    position: relative;
    left: 0.115em;
    letter-spacing: 0.1em;
  }
  .complete-message::after {
    display: block;
    content: "Complete!";
    position: absolute;
    font-family: 'Bungee Shade';
    color: var(--ion-text-color);
  }


  .grid-tools {
    width: 95vw;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-content: stretch;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .grid-tools .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .grid-tools .right {
    flex-grow: 1;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .grid-tools .undo-button {
    margin-left: 0;
    margin-right: 0.5px;
  }

  .grid-tools .undo-button::part(native) {
    border-top-right-radius:    0;
    border-bottom-right-radius: 0;
  }

  .grid-tools .redo-button {
    margin-left: 0.5px;
  }

  .grid-tools .redo-button::part(native) {
    border-top-left-radius:     0;
    border-bottom-left-radius:  0;
  }

  .grid-tools-fade-enter-active,
  .grid-tools-fade-leave-active {
    transition: all 0.6s ease;
  }

  .grid-tools-fade-enter-from,
  .grid-tools-fade-leave-to {
    opacity: 0;
    transform: translateY(20vh) scale(0.7);
  }


  .start-game-fade-enter-active,
  .start-game-fade-leave-active {
    transition: all 0.3s ease;
  }

  .start-game-fade-enter-from,
  .start-game-fade-leave-to {
    opacity: 0;
    transform: translateY(-30px) scale(0.8);
  }


  .countdown-fade-enter-active,
  .countdown-fade-leave-active,
  .countdown-fade-enter-active span,
  .countdown-fade-leave-active span {
    transition: all 0.4s ease;
  }

  .countdown-fade-enter-from span {
    opacity: 0;
    transform: scale(0.5)
  }

  .countdown-fade-leave-to span {
    opacity: 0;
    transform: scale(1.5)
  }


  #keyboard-shortcuts {
    position: absolute;
    text-align: center;
    bottom: 10px;
    left: 0;
    width: 100%;
    margin-top: 25px;
    display: none;
    font-size: 11px;
    color: var(--ion-color-medium);
  }
  @media (min-width: 1150px) and (min-height: 750px) {
    #keyboard-shortcuts {
      display: block !important;
    }
  }

  #keyboard-shortcuts i {
    font-style: normal;
    display: inline-block;
    height: 1.5em;
    width: 1.5em;
    border-radius: 2px;
    border: 0.5px solid var(--ion-color-medium);
    text-align: center;
    margin: 0 2px;
  }

  #keyboard-shortcuts i.wide {
    width: auto !important;
    padding: 0 3px;
  }

  #keyboard-shortcuts .stacked {
    display: inline-block;
    width: 1.5em;
  }

  #keyboard-shortcuts .stacked i {
    margin: 1px 0 0 0;
  }

  .waiting-host-start-msg {
    text-align: center;
    padding: 0 15px;
  }


  #debug {
    font-size: 10px;
    position: absolute;
    z-index: 100000;
    top: 10px;
    left: 10px;
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid #ccc;
    padding: 0 10px;
    backdrop-filter: blur(6px);
  }


</style>