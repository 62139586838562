<template>
  <ion-app v-if="state.loaded">
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { store } from '@/store';
import config from '@/config.ts';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },

  data() {
    return {
      state: store.state,
      mediaPrefersDark: false,
    }
  },

  computed: {
    theme() {
      const state = this.state as any
      if (config.features == 'WEB') {
        return 'light'
      } else {
        return state.theme
      }
    }
  },

  created() {
    this.initThemes();
  },

  watch: {
    mediaPrefersDark() {
      this.updateTheme();
    },
    theme() {
      this.updateTheme();
    },
  },

  methods: {
    initThemes() {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
      this.mediaPrefersDark = prefersDark.matches;
      prefersDark.addListener((mediaQuery) => {this.mediaPrefersDark = mediaQuery.matches});
      this.updateTheme();
    },
    updateTheme() {
      let theme;
      if (config.features == 'WEB') {
        theme = 'light'
      } else {
        theme = this.state.theme
      }
      if (theme == 'match') {
        theme = this.mediaPrefersDark ? 'dark' : 'light';
      }
      document.body.classList.toggle('dark-theme', (theme == 'dark'));
    }

  }
});
</script>