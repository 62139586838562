
import { 
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonItem,
  IonLabel,
} from '@ionic/vue';
import { defineComponent } from 'vue';

import * as api from '@/api.ts';
import { store } from '@/store';
import { stringToHslColor } from '@/utils';

export default defineComponent({
  name: 'PastGame',
  components: {
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonItem,
    IonLabel,
  },
  emits: ['deleted'],
  
  props: {
    gameId: String,
    gameRev: String,
  },

  mounted() {
    this.loadGame();
  },

  data() {
    return {
      game: (null as any),
      state: store.state,
    }
  },

  watch: {
    gameRev() {
      this.loadGame();
    }
  },

  computed: {
    formattedCreated(): string {
      return new Intl.DateTimeFormat([], {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
      }).format(new Date(this.game.state.created))
    }
  },

  methods: {
    async loadGame() {
      this.game = await api.getGame((this.gameId as string));
    },
    stringToHslColor(str: string, s: number, l: number) {
      return stringToHslColor(str, s, l)
    },
    async deleteGame() {
      await api.deleteGame((this.gameId as string));
      this.$emit('deleted')
    }
  }
})
