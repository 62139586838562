import { reactive } from 'vue';
import { Plugins, Capacitor } from '@capacitor/core';
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';
import PouchUpsert from 'pouchdb-upsert';
import PouchSqlite from 'pouchdb-adapter-cordova-sqlite';

const { Storage } = Plugins;

PouchDB.plugin(PouchFind);
PouchDB.plugin(PouchUpsert);
let _db;
if (Capacitor.isNative) {
  PouchDB.plugin(PouchSqlite);
  _db = new PouchDB('sudokutoo.db', {
    auto_compaction: true, // eslint-disable-line @typescript-eslint/camelcase,
    adapter: 'cordova-sqlite',    
    location: 'default',         
    // iosDatabaseLocation: 'default',
    androidDatabaseImplementation: 2,
  });
} else {
  _db = new PouchDB('sudokutoo', {
    auto_compaction: true, // eslint-disable-line @typescript-eslint/camelcase
  });
}
export const db = _db;

const storagePrefix = 'state:';

export const store  = {
  debug: true,

  state: reactive({
    loaded: false,
    theme: 'light',
    profileName: 'Unnamed',
    profilePicture: '',
    inputFont: 'default',
    inputColor: 'grey',
    showHighlights: true,
  }),

  localStoredState: [
    'theme',
    'inputFont',
    'inputColor',
    'showHighlights',
  ],

  pouchState: [
    'profileName',
    'profilePicture',
  ],

  async loadState() {
    for (const key of this.localStoredState) {
      await this.loadFromStorage(key);
    }
    for (const key of this.pouchState) {
      await this.loadFromStorage(key);
    }
    this.state.loaded = true;
  },

  async setAndStore(key: string, value: any) {
    const state = this.state as any;
    const storageKey = `${storagePrefix}${key}`;
    state[key] = value;
    if (this.localStoredState.includes(key)) {
      Storage.set({
        key: storageKey,
        value: JSON.stringify(value),
      });
    } else {
      await db.upsert(storageKey, (doc) => {
        (doc as any).value = value;
        return doc;
      })
    }
  },

  async loadFromStorage(key: string){
    const state = this.state as any;
    const storageKey = `${storagePrefix}${key}`;
    if (this.localStoredState.includes(key)) {
      const ret = await Storage.get({ key: storageKey });
      let value: any;
      if (ret.value) {
        value = JSON.parse(ret.value);
      } else {
        value = state[key];
        await Storage.set({
          key: storageKey,
          value: JSON.stringify(value),
        });
      }
      state[key] = value;
    } else if (this.pouchState.includes(key)) {
      let doc;
      try {
        (doc as any) = await db.get(storageKey);
      } catch (err) {
        doc = {
          _id: storageKey,
          value: state[key],
        }
        await db.put(doc);
      }
      state[key] = doc?.value;
    }
  },

}
