<template>
  <ion-page>
    <ion-tabs @ionTabsDidChange="tabChange">
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home" href="/home">
          <ion-icon :icon="activeTab=='home' ? iconHomeActive: iconHome" />
          <ion-label>Home</ion-label>
        </ion-tab-button>
          
        <ion-tab-button tab="past-games" href="/past-games">
          <ion-icon :icon="activeTab=='past-games' ? iconPastGamesActive: iconPastGames" />
          <ion-label>Past Games</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { 
  homeOutline,
  home,
  fileTrayFullOutline,
  fileTrayFull,
 } from 'ionicons/icons';

export default defineComponent({
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  data() {
    return {
      activeTab: String,
      iconHome: homeOutline,
      iconHomeActive: home,
      iconPastGames: fileTrayFullOutline,
      iconPastGamesActive: fileTrayFull,
    }
  },
  methods: {
    tabChange(event: any) {
      this.activeTab = event.tab;
    }
  }
})
</script>
