<template>
  <div>
    <ion-item-sliding v-if="game">
      <ion-item-options side="start" @ionSwipe="deleteGame">
        <ion-item-option
          color="danger"
          @click="deleteGame"
          expandable
        >
          Delete
        </ion-item-option>
      </ion-item-options>
      <ion-item 
        :router-link="`/game/${gameId}`"
        detail
      >
        <div slot="start">
          <div
            class="minigrid"
            v-if="game.state.grid"
          >
            <div
              class="row"
              v-for="(row, rowi) of game.state.grid"
              :key="rowi"
            >
              <div
                v-for="(col, coli) of row"
                :key="coli"
              >
              {{ col.number }}
              </div>
            </div>
          </div>
          <div
            class="nominigrid"
            v-else
          >
          </div>
        </div>
        <ion-label>
          <h3>
            <span class="game-code">{{ game.state.gameCode }}</span>
            <span class="time">{{ formattedCreated }}</span>
          </h3>
          <p>{{ game.getLevel() }} </p>
          <p class="players">
            <span
              class="player"
              v-for="player in game.state.player"
              :key="player.playerId"
            >
              <span 
                v-if="game.state.playerId == player.playerId"
                class="avatar"
                :style="{
                  backgroundImage: `url(${state.profilePicture})`,
                  backgroundColor: stringToHslColor(player.playerId, 70, 80),
                }"
              ></span>
              <span 
                v-else
                class="avatar"
                :style="{
                  backgroundImage: `url(${player.playerPicture})`,
                  backgroundColor: stringToHslColor(player.playerId, 70, 80),
                }"
              ></span>
              {{ player.name }}
            </span>
          </p>
        </ion-label>
      </ion-item>
    </ion-item-sliding>
  </div>
</template>

<script lang="ts">
import { 
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonItem,
  IonLabel,
} from '@ionic/vue';
import { defineComponent } from 'vue';

import * as api from '@/api.ts';
import { store } from '@/store';
import { stringToHslColor } from '@/utils';

export default defineComponent({
  name: 'PastGame',
  components: {
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonItem,
    IonLabel,
  },
  emits: ['deleted'],
  
  props: {
    gameId: String,
    gameRev: String,
  },

  mounted() {
    this.loadGame();
  },

  data() {
    return {
      game: (null as any),
      state: store.state,
    }
  },

  watch: {
    gameRev() {
      this.loadGame();
    }
  },

  computed: {
    formattedCreated(): string {
      return new Intl.DateTimeFormat([], {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
      }).format(new Date(this.game.state.created))
    }
  },

  methods: {
    async loadGame() {
      this.game = await api.getGame((this.gameId as string));
    },
    stringToHslColor(str: string, s: number, l: number) {
      return stringToHslColor(str, s, l)
    },
    async deleteGame() {
      await api.deleteGame((this.gameId as string));
      this.$emit('deleted')
    }
  }
})
</script>

<style scoped>
  ion-label {

  }
  ion-label h3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  ion-label p {
    overflow:  visible;
  }

  ion-label p.players {
    padding:  0.2em 0;
  }

  span.player {
    margin-right: 1em;
  }

  span.avatar {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    background-size: cover;
    border-radius: 100%;
    margin: 0 0 -0.3em 0.2em;
    border: 0.5px solid var(--ion-text-color, #000);
    box-shadow: -1.5px 1.5px 0 0 var(--ion-text-color, #000);
  }

  .nominigrid,
  .minigrid {
    width: 90px;
    height: 90px;
    border: 0.5px solid var(--ion-text-color, #000);
    box-shadow: -1.5px 1.5px 0 0 var(--ion-text-color, #000);
    border-radius: 3px;
  }

  .minigrid {
    display: flex;
    flex-direction: column;
    font-size: 8px;
  }

  .minigrid > div {
    display: flex;
    flex-direction: row;
    height: calc(100% / 9);
    border-top: 0.5px solid rgba(var(--ion-color-medium-rgb), 0.2);
  }

  .minigrid > div:nth-child(3n+1) {
    border-top: 0.5px solid rgba(var(--ion-color-medium-rgb), 0.5);
  }

  .minigrid > div:first-child {
    border-top: none;
  }

  .minigrid > div > div {
    width: calc(100% / 9);
    text-align: center;
    border-left: 0.5px solid rgba(var(--ion-color-medium-rgb), 0.2);
  }

  .minigrid > div > div:nth-child(3n+1) {
    border-left: 0.5px solid rgba(var(--ion-color-medium-rgb), 0.5);
  }

  .minigrid > div > div:first-child {
    border-left: none;
  }

  .nominigrid {
    text-align: center;
  }

  .nominigrid::after {
    content: '?';
    display: inline;
    font-size: 60px;
    color: rgba(var(--ion-color-medium-rgb), 0.2);
    line-height: 90px;
  }

</style>