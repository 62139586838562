<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title class="brand">SudokuToo</ion-title>
        <ion-buttons slot="primary">
          <SettingsButton :presentingElement="$parent.$parent.$el"></SettingsButton>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    

    <ion-content :fullscreen="true">
      <h2 class="ion-text-center">New Game</h2>
      <h4 class="ion-text-center">Play Sudoku with friends<br> and race to see who's fastest!</h4>
      <div class="main-buttons ion-text-center">
        <div>
          <ion-button expand="block" color="success" @click="newGame(1)">Easy</ion-button>
          <ion-button expand="block" color="primary" @click="newGame(2)">Medium</ion-button>
          <ion-button expand="block" color="warning" @click="newGame(3)">Hard</ion-button>
          <ion-button expand="block" color="danger" @click="newGame(4)">Expert</ion-button>
        </div>
        <br>
        <ion-button expand="block" color="light" @click="joinGameDialog()">
          <ion-icon slot="start" :ios="peopleSharp" :md="peopleSharp"></ion-icon>
          Join Game
        </ion-button>
        <!--br>
        <ion-button expand="block" color="light" router-link="/scan-grid">
          <ion-icon slot="start" :ios="scanOutline" :md="scanOutline"></ion-icon>
          Scan Sudoku Grid
        </ion-button-->
      <h4 class="ion-text-center created-by">created by <a href="https://mobile.twitter.com/samwillis">@samwillis</a></h4>
      </div>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { 
  IonContent,
  IonHeader,
  IonPage,
  IonButton,
  IonButtons, 
  IonIcon,
  IonTitle, 
  IonToolbar,
} from '@ionic/vue';

import {
  peopleSharp,
  scanOutline
} from 'ionicons/icons';

import { useRouter } from 'vue-router';
import { defineComponent } from 'vue';
import SettingsButton from '@/components/SettingsButton.vue';
import * as api from '@/api.ts';
import { errorAlert } from '@/utils';
import { prompt } from '@/utils/dialogs';
import config from '@/config.ts';


export default defineComponent({
  name: 'Home',

  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonButton, 
    IonButtons,
    IonTitle, 
    IonToolbar,
    IonIcon,
    SettingsButton,
  },

  methods: {

    async newGame(level: number) {
      let gameId: string | null;
      try {
        gameId = await api.newGame(level);
      } catch(e) {
        await errorAlert({message: e.message as string});
        return;
      }
      if (gameId) {
        this.router.push(`/game/${gameId}`);
      }
    },

    async joinGameDialog() {
      const promptRet = await prompt({
        title: 'Join Game',
        message: 'Enter the game code:'
      });
      if (promptRet.cancelled) {
        return;
      }
      this.joinGame(promptRet.value)
    },

    async joinGame(gameCode: string) {
      await api.joinGameWithCode(gameCode);
    }

  },

  setup() {
    return {
      router: useRouter(),
      peopleSharp,
      scanOutline,
      config,
    }
  }

});
</script>

<style scoped>
  .brand {
  }

  h2 {
    font-weight: normal;
    font-family: 'Bungee Outline';
    font-size: 40px;
  }

  h4 {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 25px;
  }

  .created-by {
    margin-top: 25px;
  }

  .main-buttons {
    max-width: 300px;
    align: center;
    margin-left: auto;
    margin-right: auto;
  }
</style>