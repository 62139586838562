import { reactive } from 'vue';
import axiosBase from 'axios'
import { v4 as uuidv4 } from 'uuid';
import config from '@/config.ts';
import { store, db } from '@/store';
import { loadingController } from '@ionic/vue';
import { errorAlert } from '@/utils';
import { confirm } from '@/utils/dialogs';
import router from '@/router';


const axios = axiosBase.create({
  headers: {
    common: {
      'x-api-client-version': config.apiClientVersion,
      'x-api-key': config.apiKey,
    }
  }
})


function apiUrl(protocol='http'){
  if (config.useTLS) {
    return `${protocol}s://${config.apiHost}`;
  } else {
    return `${protocol}://${config.apiHost}`;
  }
}

let _playerId: string;
export async function getPlayerId(): Promise<string> {
  if (_playerId) return _playerId;
  let doc: any;
  try {
    doc = await db.get('playerId');
    _playerId = doc.value;
  } catch (err) {
    _playerId = uuidv4();
    doc = {
      _id: 'playerId',
      value: _playerId,
    }
    await db.put(doc);
  }
  return _playerId;
}



const stateKeyTypes = [
  [/^created$/, parseFloat],
  [/^expires$/, parseFloat],
  [/^startTime$/, parseFloat],
  [/^level$/, parseInt],
  [/^known$/, parseInt],
  [/^playerCount$/, parseInt],
  [/^playerJoinCounter$/, parseInt],
  [/^playerNumber:.+$/, parseInt],
  [/^player\[\d+\]:progress$/, parseInt],
  [/^player\[\d+\]:playerNumber$/, parseInt],
  [/^player\[\d+\]:lastMove$/, parseFloat],    
  [/^player\[\d+\]:connected$/, (n: string) => Boolean(parseInt(n))],    
]

export function updateGameStateObj(currentState: any, newStateMsg: any) {
  for (const [key, value] of Object.entries(newStateMsg)) {
    let obj = currentState;
    const [keyHead, ...keyTailRev] = key.split(':').reverse();
    const keyTail = keyTailRev.reverse();
    for (const key of keyTail) {
      const keyArrayMatch = key.match(/^(.+)\[(\d+)\]$/);
      // This only suport one level of array currently
      if (keyArrayMatch){
        const keyArray = keyArrayMatch[1]
        const keyIndex = parseInt(keyArrayMatch[2])
        if (obj[keyArray] == undefined){
          obj[keyArray] = [];
        }
        if (obj[keyArray][keyIndex] == undefined){
          obj[keyArray][keyIndex] = {};
        }
        obj = obj[keyArray][keyIndex];
      } else {
        if (obj[key] == undefined){
          obj[key] = {};
        }
        obj = obj[key];
      }
    }
    let parseValue: any;
    for (const [regex, fn] of stateKeyTypes){
      if (key.match(regex as RegExp)) {
        parseValue = fn;
        break;
      }
    }
    // TODO: only change if its different
    if (parseValue) {
      obj[keyHead] = parseValue(value);
    } else {
      obj[keyHead] = value;
    }
  }
  return currentState;
}


function stringToGridMarix(gridString: string) {
  const newArray = [];
  const gridArray = gridString.split('').map((n) => parseInt(n));
  for (let i = 0; i < gridArray.length; i += 9) {
    newArray.push(gridArray.slice(i, i + 9));
  }
  return newArray;
}

function uncompressGrid(compressedGrid: string): string {
  const l = [];
  for (let i=0; i < compressedGrid.length; i++) {
    let n = compressedGrid.charCodeAt(i)
    if (n > 161) n -= 35;
    if (n > 92) n -= 1;
    n -= 35;
    l.push(n.toString().padStart(2, '0'))
  }
  return l.join('').slice(0, 81);
}

function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length;
  let temporaryValue: any;
  let randomIndex: number;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}


function datetimeuuid(now?: Date) {
  // UUID in form : 20210120-1232-1565-cc70-5102aafd5349
  //                YYYYMMDD-HHMM-SSxx-rrrr-rrrrrrrrrrrr
  // x = hundredths of a seconds
  // r = random hex
  if (!now) now = new Date();
  return [
    now.getFullYear().toString().padStart(4, '0'),
    (now.getMonth()+1).toString().padStart(2, '0'),
    now.getDay().toString().padStart(2, '0'),
    '-',
    now.getHours().toString().padStart(2, '0'),
    now.getMinutes().toString().padStart(2, '0'),
    '-',
    now.getSeconds().toString().padStart(2, '0'),
    Math.floor(now.getMilliseconds()/10).toString().padStart(2, '0'),
    uuidv4().slice(-18),
  ].join('');
} 


const gridLevelFiles = [
  '/assets/grids/grids-0.json',
  '/assets/grids/grids-1.json',
  '/assets/grids/grids-2.json',
  '/assets/grids/grids-3.json',
];

const gridTransforms: {(m: number[][]): number[][]}[] = [
  (m) => m[0].map((x,i) => m.map(x => x[i])),
  (m) => m.map((a) => a.reverse()),
  (m) => m.reverse(),
]

export async function randomGrid(level = 1): Promise<string> {
  // if (config.features == 'WEB') throw new Error('Not available!');
  const grids = (await axios.get(gridLevelFiles[level-1])).data;
  const gridCompressed = grids[Math.floor(Math.random() * grids.length)];
  let GridMarix = stringToGridMarix(uncompressGrid(gridCompressed));
  for (const f of gridTransforms) {
    if (Math.random() < 0.5) {
      GridMarix = f(GridMarix);
    }
  }
  const numbers = shuffle([1,2,3,4,5,6,7,8,9]);
  numbers.unshift(0);
  let grid = GridMarix.flat()
  grid = grid.map((n) => numbers[n])
  return grid.join('')
}


export async function createOfflineGame(level: number, grid: string) {
  // Return an initial game state as though it has come from the server
  // if (config.features == 'WEB') throw new Error('Not available!');
  const playerId = await getPlayerId();
  const name = store.state.profileName;
  const known = 81-(grid.match(/0/g)||[]).length;
  const gameId = datetimeuuid();
  const stateMsg = {
    "gameId": gameId,
    "created": Date.now().toString(),
    "hostId": playerId,
    "playerId": playerId,
    [`playerNumber:${playerId}`]: "0",
    "player[0]:playerId": playerId,
    "player[0]:name": name,
    "player[0]:lastMove": "0",
    "player[0]:connected": "1",
    "player[0]:playerNumber": "0",
    "player[0]:progress": "0",
    "playerJoinCounter": "1",
    "playerCount": "1",
    "level": level.toString(),
    "gridStart": grid,
    "known": known.toString(),
    "startTime": "0",
    "status": "open",
    "gameCode": "OFFLINE",
    "playerSecret": "",
    "wsUrl": "",
    "inviteUrl": "",
    "offlineGame": true,
  }
  return stateMsg;
}


export async function newGame(level = 1): Promise<string | null> {
  // if (config.features == 'WEB') throw new Error('Not available!');
  let response;
  let singlePlayerData;
  let data;
  const loading = await loadingController
    .create({
      message: 'Game Loading...',
      translucent: true,
    });
  await loading.present();
  const grid = await randomGrid(level);
  try {
    response = await axios.post(`${apiUrl()}/new-game`, {
      'level': level,
      'grid': grid,
      'playerId': await getPlayerId(),
      'name': store.state.profileName,
    })
  } catch(error) {
    loading.dismiss();
    console.log(error)
    if (error.response && error.response.data && error.response.data.detail) {
      throw new Error(error.response.data.detail);
    } else {
      // Unable to connect to server, could be no signal or server down...
      // Ask if they want a single player game
      // throw new Error("Unable to create new game.\n Please try again.");
      const ret = await confirm({
        title: 'Multiplayer Not Available',
        message: 'You are currently unable to create a multiplayer game.\n Would you like to play a single player game?',
        okButtonTitle: 'Play Single Player',
        // cancelButtonTitle: 'Cancel',
      })
      if (!ret.value) {
        return null;
      }
      singlePlayerData = await createOfflineGame(level, grid);
    }
  }

  if (singlePlayerData) {
    data = singlePlayerData;
  } else if(response) {
    data = response.data;
  } else {
    return null;
  }

  await db.put({
    _id: `game:${data.gameId}`,
    host: true,
    state: updateGameStateObj({}, data),
  })

  loading.dismiss();
  return data.gameId;
}


export async function getGameList(): Promise<any[]> {
  const result = await db.allDocs({
    startkey: 'game:',
    endkey: "game:\uffff",
  });
  return result.rows.map((obj) => {
    return {
      id: obj.id.slice(5),
      rev: obj.value.rev,
    }
  });
}


export async function joinGame(gameCode: string): Promise<string> {
  let response;

  try {
    response = await axios.post(`${apiUrl()}/join-game`, {
      'gameCode': gameCode,
      'playerId': await getPlayerId(),
      'name': store.state.profileName,
    })
  } catch(error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail);
    } else {
      throw new Error("Unable to join new game.\n Please try again.");
    }
  }

  await db.put({
    _id: `game:${response.data.gameId}`,
    host: false,
    state: updateGameStateObj({}, response.data),
  })

  return response.data.gameId;
}


export async function saveProfilePicture(playerId: string, profilePicure: string) {
  await db.upsert(`profilePicture:${playerId}`, (doc) => {
    (doc as any).value = profilePicure;
    return doc;
  })
}


export async function getProfilePicture(playerId: string) {
  let doc: any
  try {
    doc = await db.get(`profilePicture:${playerId}`)
  } catch (err) {
    return undefined
  }
  return doc.value;
}


export class Game {
  ws: (WebSocket | null) = null;
  wsReconnect = false;
  connected = false;
  timeSyncStarted = false;
  timeOffset = 0;
  timeOffsets: number[] = [];
  reconnectCounter = 0;
  state: any = reactive({
    requestedProfilePictures: [],
    progress: 0,
    offlineGame: false,
  });
  profilePictures: any = reactive({});

  constructor(state: object) {
    this.updateState(state, false);
  }

  updateState(state: object, save=true) {
    updateGameStateObj(this.state, state);
    if (save) {
      this.saveState();
    }
  }

  async saveState(){
    if (this.state.gameId) {
      await db.upsert(`game:${this.state.gameId}`, (doc) => {
        (doc as any).state = this.state;
        return doc;
      })
    }
  }

  connect(reconnect=true){
    // Connect to the websocket
    if (this.state.offlineGame) return;
    if (this.ws) return;
    this.wsReconnect = reconnect;
    this.ws = new WebSocket(`${apiUrl('ws')}${this.state.wsUrl}`);

    this.ws.onerror = (event) => {
      console.log('Websocket Error', event);
    }

    this.ws.onclose = (event) => {
      this.connected = false;
      this.ws = null;
      if (this.wsReconnect) {
        this.reconnectCounter += 1;
        const reconnectTimeoutSec = Math.min(this.reconnectCounter, 20);
        console.log(`Websocket Disconnected - Reconnecting in ${reconnectTimeoutSec}s`, event);
        setTimeout(() => {
          if (this.wsReconnect) {
            console.log('Websocket Reconnecting');
            this.connect(true);
          }
        }, reconnectTimeoutSec*1000)
      } else {
        console.log('Websocket Disconnected', event);
      }
    }

    this.ws.onopen = () => {
      this.connected = true;
      this.reconnectCounter = 0;
      this.wsSend({
        'type': 'connected',
      })
      this.timeSyncStart();
      if (this.state.status == 'active') {
        this.updateProgress();
      }
    }

    this.ws.onmessage = (event) => {
      this.wsReceive(JSON.parse(event.data));
    }
  }

  disconnect(msg?: any){
    // Disconnect from the websocket
    if (this.ws){
      const ws = this.ws;
      this.wsReconnect = false;
      this.ws = null;
      ws.onmessage = null;
      ws.onerror = null;
      ws.onclose = null;
      if (msg) {
        ws.send(JSON.stringify(msg));
        setTimeout(() => {
          ws.close();
        }, 500);
      } else {
        ws.close();
      }
    }
    this.connected = false;
  }

  wsReceive(msg: any) {
    if (msg.type=='state_update'){
      this.updateState(msg.state);
      this.checkProfilePictures();

    } else if (msg.type=='time_sync_reply'){
      this.timeSyncReceive(msg)

    } else if (msg.type=='profile_picture_please'){
      this.handleProfilePictureRequest(msg)

    } else if (msg.type=='profile_picture'){
      this.handleProfilePicture(msg)

    } else if (msg.type=='profile_name'){
      this.handleProfileName(msg)

    } else if (msg.type=='error'){
      console.log(msg)
      // TODO: emit error events?
      if (msg.fatal) {
        // If it is a fatal error stop the websocket from reconnecting
        // This is sent before the server disconnects itself.
        this.wsReconnect = false;
      }
    }
  }

  wsSend(msg: any) {
    if (this.state.offlineGame) return;
    if (!this.ws) {
      throw new Error("Websocket not connected.");
    }
    this.ws.send(JSON.stringify(msg));
  }

  timeSyncStart() {
    this.timeSyncStarted = true;
    this.timeOffsets = [];
    this.timeSyncSend();
  }

  timeSyncSend() {
    const now = Date.now();
    if (this.ws) {
      this.wsSend({
        'type': 'time_sync',
        'client_sent': now,
      })
    }
  }

  timeSyncReceive(msg: any) {
    const now = Date.now();
    const latency = (now - msg.client_sent) / 2;
    const actualServerTime = msg.server_time - latency;
    const offset = actualServerTime - now;
    this.timeOffsets.push(offset);
    const timeOffsets = this.timeOffsets.slice(); // Shallow copy of array
    if (timeOffsets.length > 4){
      timeOffsets.sort((a, b) => a - b);
      // remove top and bottom
      timeOffsets.pop();
      timeOffsets.shift();
    }
    const sum = timeOffsets.reduce((a, b) => a + b, 0);
    const avg = (sum / timeOffsets.length) || 0;
    this.timeOffset = avg;
    if (this.timeOffsets.length < 9) {
      setTimeout(() => {
        this.timeSyncSend();
      }, 500)
    }
  }

  time() {
    // NOTE: Time can go backwards while we sync with the server!!
    return Date.now() + this.timeOffset;
  }

  async checkProfilePictures() {
    for (const player of this.state.player) {
      if (player.playerId == await getPlayerId()) continue;
      const profilePicture = await getProfilePicture(player.playerId);
      if (profilePicture) {
        this.profilePictures[player.playerId] = profilePicture;
      }
      if (!this.state.requestedProfilePictures.includes(player.playerId)) {
        this.state.requestedProfilePictures.push(player.playerId);
        this.wsSend({
          'type': 'profile_picture_please',
          'to': player.playerId,
        })
      }
    }
  }

  sendProfilePicture(to: string) {
    this.wsSend({
      'type': 'profile_picture',
      'to': to,
      'playerPicture': store.state.profilePicture,
    })
  }

  sendProfileName(to: string) {
    this.wsSend({
      'type': 'profile_name',
      'to': to,
      'name': store.state.profileName,
    })
  }

  handleProfilePictureRequest(msg: any) {
    this.sendProfilePicture(msg.from)
  }

  handleProfilePicture(msg: any) {
    const fromPlayerNumber = this.state.playerNumber[msg.from]
    if (fromPlayerNumber >= 0) {
      this.profilePictures[msg.from] = msg.playerPicture;
      saveProfilePicture(msg.from, msg.playerPicture);
    }
    this.saveState();
  }

  handleProfileName(msg: any) {
    const fromPlayerNumber = this.state.playerNumber[msg.from]
    if (fromPlayerNumber >= 0) {
      this.state.player[fromPlayerNumber].name = msg.name
    }
    this.saveState();
  }

  start() {
    if (this.state.offlineGame) { return this.startOffline() }
    if (this.state.player.length == 1) {
      // If there is only one player, convert this into an offline game and start
      this.state.offlineGame = true;
      this.disconnect({
        'type': 'convert_offline',
      });
      this.startOffline();
    } else {
      this.sendStart();
    }
  }

  startOffline() {
    const gameCountdown = 3000;
    this.state.startTime = Date.now() + gameCountdown,
    this.state.status = 'active';
    this.saveState();
  }

  sendStart() {
    this.wsSend({
      'type': 'start',
    })
  }

  initGrid() {
    if (this.state.grid) return;
    const numbers = this.state.gridStart.split('').map((ns: string) => {
      const n = parseInt(ns);
      return  {
        'number': n > 0 ? n : null,
        'preset': n > 0,
        'notes': [],
      }
    });
    const grid = []
    for (let i=0; i<9; i++) {
      grid.push(numbers.slice(9*i, (9*i)+9))
    }
    this.state.grid = grid;
    this.state.undoStack = [];
    this.state.undoStackCursor = -1;
    this.pushUndoStack();
  }

  pushUndoStack() {
    this.state.undoStackCursor += 1;
    this.state.undoStack.splice(this.state.undoStackCursor);
    const gridClone = JSON.parse(JSON.stringify(this.state.grid));
    this.state.undoStack.push(gridClone);
    this.saveState();
  }

  undo() {
    if (this.state.undoStackCursor <= 0) return; // Cant undo past tail
    this.state.undoStackCursor -= 1;
    const gridClone = JSON.parse(JSON.stringify(this.state.undoStack[this.state.undoStackCursor]));
    this.state.grid = gridClone;
    this.saveState();
  }

  redo() {
    if ((this.state.undoStackCursor+1) >= this.state.undoStack.length) return; // Cant redo past head
    this.state.undoStackCursor += 1;
    const gridClone = JSON.parse(JSON.stringify(this.state.undoStack[this.state.undoStackCursor]));
    this.state.grid = gridClone;
    this.saveState();
  }

  updateProgress() {
    let progress = 0;
    for (let row=0; row<9; row++) {
      for (let col=0; col<9; col++) {
        if (this.state.grid[row][col].number) {
          progress += 1;
        }
      }
    }
    if (progress == 81 && !this.validateSudoku()) {
      // This is not a valid sudoku solution!
      progress = 80;
    }
    this.updateSelfProgress(progress);
    this.state.progress = progress;
    this.saveState();
    this.sendProgress();
    return [progress, progress==81];
  }

  sendProgress() {
    if (this.state.offlineGame) return;
    const msg: {
      'type': string;
      'value': number;
      'grid'?: (number | null)[][];
    } = {
      'type': 'progress_update',
      'value': this.state.progress,
    }
    if (this.state.progress == 81) {
      msg['grid'] = this.getGrid();
    }
    this.wsSend(msg);
  }

  updateSelfProgress(progress: number) {
    const playerId = this.state.playerId;
    const playerNumber = this.state.playerNumber[playerId];
    this.state.player[playerNumber].progress = progress;
    this.state.player[playerNumber].lastMove = this.time();
    // lastMove will be overwritten by response from server although it should be 
    // very close as we have synced the clocks.
  }

  validateSudoku() {
    const toCheck = [];
    for (let i=0; i<9; i++) {
      toCheck.push(this.getRow(i).sort((a, b) => a - b))
      toCheck.push(this.getCol(i).sort((a, b) => a - b))
      toCheck.push(this.getSubGrid(i).sort((a, b) => a - b))
    }
    for (let i=0; i<toCheck.length; i++) {
      for (let n=0; n<9; n++) {
        if (toCheck[i][n] != n+1){
          return false;
        }
      }
    }
    return true;
  }

  getRow(row: number) {
    const ret = [];
    for (let col=0; col<9; col++) {
      ret.push(this.state.grid[row][col].number)
    }
    return ret;
  }

  getCol(col: number) {
    const ret = [];
    for (let row=0; row<9; row++) {
      ret.push(this.state.grid[row][col].number)
    }
    return ret;
  }

  getSubGrid(num: number) {
    const ret = [];
    const rowStart = Math.floor(num/3)*3;
    const colStart = (num%3)*3;
    for (let row=rowStart; row<(rowStart+3); row++) {
      for (let col=colStart; col<(colStart+3); col++) {
        ret.push(this.state.grid[row][col].number)
      }
    }
    return ret;
  }

  getGrid() {
    const grid = [];
    for (let row=0; row<9; row++) {
      const gridRow: (number|null)[] = [];
      grid.push(gridRow);
      for (let col=0; col<9; col++) {
        gridRow.push(this.state.grid[row][col].number)
      }
    }
    return grid;
  }

  getLevel() {
    const level = this.state.level as number;
    const levels = [
      'Easy',
      'Medium',
      'Hard',
      'Expert',
    ]
    return levels[level-1];
  }

  getClockTime() {
    if (this.state.status != 'active') return 0;
    const playerId = this.state.playerId;
    const playerNumber = this.state.playerNumber[playerId];
    const progress = this.state.player[playerNumber].progress;
    const lastMove = this.state.player[playerNumber].lastMove;
    let clock;
    if (progress==81) {
      clock = Math.max((lastMove - this.state.startTime), 0)
    } else {
      clock = Math.max((this.time() - this.state.startTime), 0)
    }
    if (this.state.offlineGame) {
      clock -= this.getPausedTime();
    }
    return clock;
  }

  getPausedTime() {
    if (!this.state.elapsedPausedTime) return 0;
    return this.state.elapsedPausedTime;
  }

  pauseTimer() {
    this.state.pausedAt = this.time();
    this.saveState();
  }

  unpauseTimer() {
    if (this.state.pausedAt) {
      const elapsedPausedTime = this.time() - this.state.pausedAt;
      this.state.pausedAt = null;
      if (!this.state.elapsedPausedTime) {
        this.state.elapsedPausedTime = elapsedPausedTime;
      } else {
        this.state.elapsedPausedTime += elapsedPausedTime;
      }
      this.saveState();
    }
  }
}


export async function getGame(gameId: string): Promise<Game> {
  let doc: any
  try {
    doc = await db.get(`game:${gameId}`);
  } catch (err) {
    throw new Error("Game not found.");
  }
  const game = new Game(doc.state);
  return game;
}


export async function deleteGame(gameId: string): Promise<void> {
  await db.upsert(`game:${gameId}`, (doc) => {
    (doc as any)._deleted = true;
    return doc;
  })
}

export async function joinGameWithCode(gameCode: string): Promise<void> {
  let gameId: string;
  const loading = await loadingController
    .create({
      message: 'Game Loading...',
      translucent: true,
    });
  await loading.present();
  try {
    if (gameCode.includes('/')) {
      // Some one has pasted the url not the code
      gameCode = gameCode.substr(gameCode.lastIndexOf('/')+1)
    }
    gameId = await joinGame(gameCode);
  } catch(e) {
    loading.dismiss();
    await errorAlert({message: (e.message as string)});
    return;
  }
  loading.dismiss();
  router.push(`/game/${gameId}`);
}
