
import { defineComponent } from 'vue';
import { IonButton } from '@ionic/vue';

export default defineComponent({
  name: 'NumPad',
  emits: ['num-click'],
  components: { IonButton },
  props: {
    disabled: Boolean,
  },
  methods: {
    numClicked(num: number) {
      this.$emit('num-click', {num})
    },
  }
})
