<template>
  <div class="num-pad">
    <ion-button
      v-for="n in 9"
      :key="n"
      :disabled="disabled"
      @click="numClicked(n)"
    >
      {{ n }}
    </ion-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonButton } from '@ionic/vue';

export default defineComponent({
  name: 'NumPad',
  emits: ['num-click'],
  components: { IonButton },
  props: {
    disabled: Boolean,
  },
  methods: {
    numClicked(num: number) {
      this.$emit('num-click', {num})
    },
  }
})
</script>

<style scoped>
  .num-pad {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: stretch;
    align-content: stretch;
    width: 95%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .num-pad ion-button {
    flex-grow: 1;
    margin: 0.5px;
    width: 10%;
  }
  
  .num-pad ion-button:not(:first-child)::part(native) {
    border-top-left-radius:     0;
    border-bottom-left-radius:  0;
  }
  
  .num-pad ion-button:not(:last-child)::part(native) {
    border-top-right-radius:    0;
    border-bottom-right-radius: 0;
  }
</style>