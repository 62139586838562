
import { defineComponent } from 'vue';
import { stringToHslColor } from '@/utils';
import { store } from '@/store';

export default defineComponent({
  name: 'PlayerAvatar',
  components: {  },
  props: {
      player: Object,
      showName: Boolean,
      game: Object,
  },

  data(){
    return {
      state: store.state,
    }
  },

  computed: {
    initials(): string {
      if (!this.player) return '';
      const names = this.name.split(' ');
      if (names.length > 1) {
        const firstName = names[0].trim();
        const lastName = names[names.length-1].trim()
        return firstName.slice(0,1) + lastName.slice(0,1)
      } else {
        return this.name.trim().slice(0,2)
      }
    },
    color(): string {
      if (!this.player) return '#ddd;';
      return stringToHslColor(this.player.playerId, 70, 80);
    },
    isSelf(): boolean {
      const game = this.game as any;
      const player = this.player as any;
      return (game.state.playerId == player.playerId);
    },
    profilePictures() {
      const game = this.game as any;
      return game.profilePictures;
    },
    playerPicture(): string {
      const player = this.player as any;
      if (this.isSelf) {
        return store.state.profilePicture;
      } else {
        return this.profilePictures[player.playerId];
      }
    },
    name(): string {
      const player = this.player as any;
      if (this.isSelf) {
        return store.state.profileName
      } else {
        return player.name
      }
    },
    position(): string {
      const player = this.player as any;
      const game = this.game as any;
      if (player.pos <= 3 && game.state.player.length > 1) {
        return player.pos.toString();
      } else {
        return '';
      }
    }
  },

  methods: {
  },
})

