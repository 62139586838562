export default {
  apiHost: process.env.VUE_APP_API_HOST,
  useTLS: process.env.VUE_APP_USE_TLS != 'false',
  apiKey: process.env.VUE_APP_API_KEY,
  features: process.env.VUE_APP_FEATURES,
  apiClientVersion: 1,
  statusCheckURL: [
    'https://statuscheck.playsudokutoo.com/statusV1.json',
    // 'https://TODO-RAW-S3-URL/statusV1.json',
  ],
}