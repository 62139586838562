<template>
  <div class="sudoku-grid">
    <svg viewBox="0 0 1000 1000">
      <g id="grid">
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="50" y1="850" x2="950" y2="850"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="50" y1="750" x2="950" y2="750"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="50" y1="650" x2="950" y2="650"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="50" y1="550" x2="950" y2="550"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="50" y1="450" x2="950" y2="450"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="50" y1="350" x2="950" y2="350"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="50" y1="250" x2="950" y2="250"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="50" y1="150" x2="950" y2="150"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="850" y1="50" x2="850" y2="950"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="750" y1="50" x2="750" y2="950"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="650" y1="50" x2="650" y2="950"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="550" y1="50" x2="550" y2="950"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="450" y1="50" x2="450" y2="950"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="350" y1="50" x2="350" y2="950"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="250" y1="50" x2="250" y2="950"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMinor" x1="150" y1="50" x2="150" y2="950"/>
      </g>
      <g>
        <!--rect vector-effect="non-scaling-stroke" x="50" y="50" class="gridLineMajor" width="900" height="900"/-->
        <line vector-effect="non-scaling-stroke" class="gridLineMajor" x1="50" y1="650" x2="950" y2="650"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMajor" x1="50" y1="350" x2="950" y2="350"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMajor" x1="650" y1="50" x2="650" y2="950"/>
        <line vector-effect="non-scaling-stroke" class="gridLineMajor" x1="350" y1="50" x2="350" y2="950"/>
      </g>
    </svg>
    <div class="sudoku-grid-row" v-for="(n, row) in 9" :key="row">
      <GridCell 
        class="sudoku-grid-cell" 
        v-for="(n, col) in 9" :key="col"
        :game="game"
        :row="row"
        :col="col"
        :enabled="enabled"
        @click="cellClicked(col, row)"
      ></GridCell>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as api from '@/api.ts';
import GridCell from './GridCell.vue';

export default defineComponent({
  name: 'Grid',
  emits: ['cell-click'],
  props: {
    game: api.Game,
    enabled: Boolean,
  },
  components: {
    GridCell,
  },
  methods: {
    cellClicked(col: number, row: number) {
      this.$emit('cell-click', {col, row})
    },
  }
})
</script>

<style scoped>

  .sudoku-grid {
    --grid-border-radius: 8px;

    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    height: 100%;
    /*width: min(95vw, 55vh);
    height: min(95vw, 55vh);
    max-width: 500px;
    max-height: 500px;*/
    margin: 0 auto;
    user-select: none;
    color: var(--ion-text-color, #000);
    /* border: 0.5px solid rgba(var(--ion-color-medium-rgb), 0.5);*/
    border: 1px solid var(--ion-text-color, #000);
    border-radius: var(--grid-border-radius);
  }

  .sudoku-grid > svg {
    position: absolute;
    display: block;
    top: -5.5%;
    left: -5.5%;
    width: 111%;
    height: 111%;
    z-index: 1;
    pointer-events: none;
  }

	.gridLineMinor{
    fill: none;
    stroke: var(--ion-color-medium, #92949c);
    stroke-width: 0.5px;
  }

	.gridLineMajor{
    fill: none;
    stroke: var(--ion-text-color, #000);
    stroke-width: 0.5px;
  }

  .sudoku-grid-row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: stretch;
    align-content: stretch;
    height: calc(100%/9);
  }
  
  .sudoku-grid-cell {
    flex-grow: 1;
    width: calc(100%/9);
  }

  .sudoku-grid-row:first-of-type .sudoku-grid-cell:first-of-type {
    border-top-left-radius: calc(var(--grid-border-radius) - 0.5px);
  }
  .sudoku-grid-row:first-of-type .sudoku-grid-cell:last-of-type {
    border-top-right-radius: calc(var(--grid-border-radius) - 0.5px);
  }
  .sudoku-grid-row:last-of-type .sudoku-grid-cell:first-of-type {
    border-bottom-left-radius: calc(var(--grid-border-radius) - 0.5px);
  }
  .sudoku-grid-row:last-of-type .sudoku-grid-cell:last-of-type {
    border-bottom-right-radius: calc(var(--grid-border-radius) - 0.5px);
  }
</style>