
import { 
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import PastGame from '@/components/PastGame.vue';

import * as api from '@/api.ts';

export default defineComponent({
  name: 'PastGames',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonList,
    PastGame
  },

  data() {
    return {
      games: ([] as any[]),
      loaded: false,
    }
  },
  
  ionViewWillEnter() {
    this.loadGames();
  },

  methods: {
    async loadGames() {
      this.games = (await api.getGameList()).reverse();
      this.loaded = true;
    },
    deleted(gameId: string) {
      this.games = this.games.filter(item => item !== gameId)
    }
  }
})
