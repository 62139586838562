<template>
  <div class="player-wrapper">
    <div
      class="player"
      :class="{connected: player.connected}"
    >
      <div 
        class="player-image"
        :style="{'background-color': color}"
      > 
        <div class="img" v-if="playerPicture"
          :style="{backgroundImage: `url(${playerPicture})`}"
        ></div>
        <div class="chars" v-else>
          {{ initials }}
        </div>
      </div>
      <div class="player-connection-status">?</div>
      <transition name="fade">
        <div class="player-name" v-if="showName">
          {{ name }}
        </div>
      </transition>
      <transition name="fade">
        <div class="position" v-if="position">
          {{ position }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { stringToHslColor } from '@/utils';
import { store } from '@/store';

export default defineComponent({
  name: 'PlayerAvatar',
  components: {  },
  props: {
      player: Object,
      showName: Boolean,
      game: Object,
  },

  data(){
    return {
      state: store.state,
    }
  },

  computed: {
    initials(): string {
      if (!this.player) return '';
      const names = this.name.split(' ');
      if (names.length > 1) {
        const firstName = names[0].trim();
        const lastName = names[names.length-1].trim()
        return firstName.slice(0,1) + lastName.slice(0,1)
      } else {
        return this.name.trim().slice(0,2)
      }
    },
    color(): string {
      if (!this.player) return '#ddd;';
      return stringToHslColor(this.player.playerId, 70, 80);
    },
    isSelf(): boolean {
      const game = this.game as any;
      const player = this.player as any;
      return (game.state.playerId == player.playerId);
    },
    profilePictures() {
      const game = this.game as any;
      return game.profilePictures;
    },
    playerPicture(): string {
      const player = this.player as any;
      if (this.isSelf) {
        return store.state.profilePicture;
      } else {
        return this.profilePictures[player.playerId];
      }
    },
    name(): string {
      const player = this.player as any;
      if (this.isSelf) {
        return store.state.profileName
      } else {
        return player.name
      }
    },
    position(): string {
      const player = this.player as any;
      const game = this.game as any;
      if (player.pos <= 3 && game.state.player.length > 1) {
        return player.pos.toString();
      } else {
        return '';
      }
    }
  },

  methods: {
  },
})

</script>

<style scoped>

  .player-wrapper {
    --avatar-size: 32px;
    position: relative;
    width: 0px;
    height: var(--avatar-size);
  }

  .player {
    position: absolute;
    left: 0.5px;
    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: 100%;
    background: #fff;
    transform: translateX(-50%);
    box-shadow: -2px 2px 0 0 var(--ion-text-color, #000);
  }

  .player-image {
    position: absolute;
    background: #ddd;
    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: 100%;
    border: 0.5px solid var(--ion-text-color, #000);
    overflow: hidden;
  }

  .player-image .img {
    position: absolute;
    width: calc(var(--avatar-size) - 2px);
    height: calc(var(--avatar-size) - 2px);
    background-size: cover;
  }

  .player-image .chars {
    position: absolute;
    width: calc(var(--avatar-size) - 2px);
    height: calc(var(--avatar-size) - 2px);
    color: var(--ion-text-color, #000);
    font-family: 'Bungee';
    font-size: 16px;
    line-height: 14px;
    border-radius: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .player:not(.connected) .player-image {
    opacity: 0.5;
  }

  .player:not(.connected) .player-image .chars,
  .player:not(.connected) .player-image .img {
    /*filter: blur(1px);*/
  }

  .player-connection-status {
    position: absolute;
    font-size: 14px;
    line-height: 12px;
    font-weight: bold;
    top: 1px;
    right: 1px;
    display: block;
  }

  .player.connected .player-connection-status {
    display: none;
  }

  .player-name {
    font-size: 10px;
    position: absolute;
    text-align: center;
    width: 100px;
    bottom: -15px;
    left: calc(var(--avatar-size)/2);
    transform: translateX(-50%)
  }

  .position {
    font-size: 10px;
    position: absolute;
    text-align: center;
    width: 100px;
    bottom: -15px;
    left: calc(var(--avatar-size)/2);
    transform: translateX(-50%)
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.15s;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

</style>