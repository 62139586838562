<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Past Games</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-list v-if="loaded">
        <transition-group
          tag="div"
          name="slide-list"
        >
          <past-game
            class="slide-list-item"
            v-for="gameId in games"
            :key="gameId.id"
            :gameId="gameId.id"
            :gameRev="gameId.rev"
            @deleted="deleted(gameId)"
          ></past-game>
        </transition-group>
      </ion-list>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import PastGame from '@/components/PastGame.vue';

import * as api from '@/api.ts';

export default defineComponent({
  name: 'PastGames',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonList,
    PastGame
  },

  data() {
    return {
      games: ([] as any[]),
      loaded: false,
    }
  },
  
  ionViewWillEnter() {
    this.loadGames();
  },

  methods: {
    async loadGames() {
      this.games = (await api.getGameList()).reverse();
      this.loaded = true;
    },
    deleted(gameId: string) {
      this.games = this.games.filter(item => item !== gameId)
    }
  }
})
</script>

<style scoped>

.slide-list-item {
  transition: all 0.3s ease-in;
}

.slide-list-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.slide-list-leave-active {
  position: absolute;
}

</style>