import { alert } from './dialogs';

export default async function(args: {
  title?: string;
  message: string;
  buttonTitle?: string;
}){
  return alert({
    title: args.title || 'Error',
    message: args.message,
    buttonTitle: args.buttonTitle || 'OK',
  })
}