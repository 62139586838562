
import { 
  IonContent,
  IonHeader,
  IonPage,
  IonButton,
  IonButtons, 
  IonIcon,
  IonTitle, 
  IonToolbar,
} from '@ionic/vue';

import {
  peopleSharp,
  scanOutline
} from 'ionicons/icons';

import { useRouter } from 'vue-router';
import { defineComponent } from 'vue';
import SettingsButton from '@/components/SettingsButton.vue';
import * as api from '@/api.ts';
import { errorAlert } from '@/utils';
import { prompt } from '@/utils/dialogs';
import config from '@/config.ts';


export default defineComponent({
  name: 'Home',

  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonButton, 
    IonButtons,
    IonTitle, 
    IonToolbar,
    IonIcon,
    SettingsButton,
  },

  methods: {

    async newGame(level: number) {
      let gameId: string | null;
      try {
        gameId = await api.newGame(level);
      } catch(e) {
        await errorAlert({message: e.message as string});
        return;
      }
      if (gameId) {
        this.router.push(`/game/${gameId}`);
      }
    },

    async joinGameDialog() {
      const promptRet = await prompt({
        title: 'Join Game',
        message: 'Enter the game code:'
      });
      if (promptRet.cancelled) {
        return;
      }
      this.joinGame(promptRet.value)
    },

    async joinGame(gameCode: string) {
      await api.joinGameWithCode(gameCode);
    }

  },

  setup() {
    return {
      router: useRouter(),
      peopleSharp,
      scanOutline,
      config,
    }
  }

});
