import { 
  alertController, 
  modalController,
  popoverController,
  actionSheetController,
} from '@ionic/vue';
import { 
  Plugins,
  Capacitor,
  AlertOptions,
  ConfirmOptions,
  ConfirmResult,
  PromptOptions,
  PromptResult,
  ActionSheetOptions,
  ActionSheetOptionStyle,
  ActionSheetResult,
  KeyboardResize,
} from '@capacitor/core';
import PopoverActions from '@/components/PopoverActions.vue';
const { Modals, Keyboard } = Plugins;

export { ActionSheetOptionStyle };
// Ref: https://github.com/ionic-team/capacitor-plugins/blob/main/dialog/src/definitions.ts


const platform = Capacitor.platform || 'web'; // 'web', 'ios', 'android', 'electron'
// const native = true;
const native = ['ios', 'android', 'electron'].includes(platform);
const nativeMobile = ['ios', 'android'].includes(platform);
const canKeyboard = Capacitor.isPluginAvailable('Keyboard');


async function disableKeyboardResize() {
  if (!canKeyboard) return;
  try {
    await Keyboard.setResizeMode({mode: KeyboardResize.None})
  } catch (e) {
    // Noop (Not implimented on android...)
  }
}

async function enableKeyboardResize() {
  if (!canKeyboard) return;
  try {
    await Keyboard.setResizeMode({mode: KeyboardResize.Native})
  } catch (e) {
    // Noop (Not implimented on android...)
  }
}


export async function alert(options: AlertOptions) {
  if (native) {
    await Modals.alert(options);
    return;
  } else {
    const alert = await alertController
      .create({
        header: options.title,
        message: options.message.replace('\n', '<br>'),
        buttons: [options.buttonTitle || 'OK'],
        translucent: true,
      });
    alert.present();
    await alert.onDidDismiss();
    return;
  }
}


export async function confirm(options: ConfirmOptions): Promise<ConfirmResult> {
  if (native) {
    return await Modals.confirm(options);
  } else {
    let value = false;
    const alert = await alertController
      .create({
        header: options.title,
        message: options.message.replace('\n', '<br>'),
        buttons: [
          {
            text: options.cancelButtonTitle || "Cancel",
            role: 'cancel',
          },
          {
            text: options.okButtonTitle || "OK",
            handler: () => {
              value = true;
            }
          },
        ],
        translucent: true,
      });
    alert.present();
    await alert.onDidDismiss();
    return { value };
  }
}


export async function prompt(options: PromptOptions): Promise<PromptResult> {
  if (native) {
    // Disable the view resize for when showing a native prompt
    await disableKeyboardResize()
    const ret = await Modals.prompt(options);
    await enableKeyboardResize()
    return ret
  } else {
    const ret: PromptResult = {
      value: '',
      cancelled: true,
    };
    const alert = await alertController
      .create({
        header: options.title,
        message: options.message.replace('\n', '<br>'),
        inputs: [
          {
            name: 'value',
            value: options.inputText || '',
            placeholder: options.inputPlaceholder || '',
          },
        ],
        buttons: [
          {
            text: options.cancelButtonTitle || "Cancel",
            role: 'cancel',
          },
          {
            text: options.okButtonTitle || "OK",
            handler: (event) => {
              ret.value = event.value;
              ret.cancelled = false;
            }
          },
        ],
        translucent: true,
      });
    alert.present();
    await alert.onDidDismiss();
    return ret;
  }
}


export async function showActions(options: ActionSheetOptions): Promise<ActionSheetResult> {
  if (nativeMobile) {
    return await Modals.showActions(options);
  } else {
    const ret: ActionSheetResult = {
      index: 0,
    };
    let cancelable = false;
    const buttons: any = [];
    for (let i = 0; i < options.options.length; i++) {
      const opt = options.options[i];
      if (opt.style == ActionSheetOptionStyle.Cancel) {
        cancelable = true;
      }
      buttons.push({
        text: opt.title,
        role: opt.style?.toLowerCase(),
        // icon: opt.icon, // these look bad
        handler: () => {
          ret.index = i;
        },
      })
    }
    const actionSheet = await actionSheetController
      .create({
        header: options.title,
        subHeader:options.message,
        backdropDismiss: cancelable,
        translucent: true,
        buttons,
      });
    actionSheet.present();
    await actionSheet.onDidDismiss();
    return ret;
  }
}


export async function modal(component: any, props: {}, options: {}) {
  const actualProps = {
    ...props,
    modalEl: (null as any),
  }
  const modal = await modalController
    .create({
      component: component,
      componentProps: actualProps,
      presentingElement: document.getElementById('app-content') || undefined,
      swipeToClose: true, // TODO: only on mobile
      ...options,
    });
    actualProps.modalEl = modal;
  return modal.present();
}


export async function popover(ev: Event, component: any, props: {}, options: {}) {
  const actualProps = {
    ...props,
    popoverEl: (null as any),
  }
  const popover = await popoverController
    .create({
      component: component,
      componentProps: actualProps,
      event: ev,
      ...options,
    });
    actualProps.popoverEl = popover;
  return popover.present();
}


export async function showPopoverActions(ev: Event, options: ActionSheetOptions): Promise<ActionSheetResult> {
  const ret: ActionSheetResult = {
    index: -1,
  };
  const buttons: any = [];
  for (let i = 0; i < options.options.length; i++) {
    const opt = options.options[i];
    if (opt.style == ActionSheetOptionStyle.Cancel) {
      ret.index = i;
    }
    buttons.push({
      text: opt.title,
      roll: opt.style?.toLowerCase(),
      icon: opt.icon,
      handler: () => {
        ret.index = i;
        popoverController.dismiss();
      },
    })
  }
  const popover = await popoverController
    .create({
      component: PopoverActions,
      componentProps: {
        buttons,
      },
      event: ev,
    })
  popover.present();
  await popover.onWillDismiss();
  return ret;
}


export async function showResponsivePopoverActions(ev: Event, options: ActionSheetOptions): Promise<ActionSheetResult> {
  if (window.innerWidth < 500) {
    return showActions(
      {
        ...options,
        options: [
          ...options.options,
          {
            title: 'Cancel',
            style: ActionSheetOptionStyle.Cancel
          },
        ],
      }
    );
  } else {
    return showPopoverActions(ev, options);
  }
}

