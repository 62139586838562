<template>
  <div class="players">
    <transition-group name="fade">
      <player-avatar 
        class="player-avatar"
        v-for="player in players" 
        :key="player.playerId"
        :player="player"
        :game="game"
        :showName="game.state.status == 'open'"
        :style="player.styles"
      ></player-avatar>
    </transition-group>
    <transition name="fade">
      <div class="progress-ticks" v-if="game.state.status == 'active'">
        <div
          v-for="tick in progressTicks"
          :key="tick.i"
          :style="tick.styles"
          :class="{'has-player': tick.hasPlayer}"
        ></div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
 } from '@ionic/vue';
import PlayerAvatar from './PlayerAvatar.vue';

export default defineComponent({
  name: 'Players',
  components: { 
    PlayerAvatar,
  },
  props: {
    game: Object,
  },

  data() {
    return {
      windowWidth: 0,
      padding: 32,
    }
  },

  // methods: {
  // },

  mounted() {
    const windowResize = () => {
      this.windowWidth = window.innerWidth;
    }
    window.addEventListener('resize', windowResize);
    windowResize();
  },

  computed: {
    completeCount() {
      const game = this.game as any;
      return game.state.player.filter((p: any) => p.progress == 81).length;
    },

    players() {
      const game = this.game as any;
      const players = [];
      for (const _player of game.state.player) {
        const player: any = {};
        for (const [key, value] of Object.entries(_player)) {
          player[key] = value
        }
        players.push(player)
      }

      const padding = this.padding;
      const displayWidth = this.windowWidth - (padding*2);
      const playerCount = players.length;
      if (game.state.status == 'open') {
        // Positions for open screen
        const spacing = displayWidth / (playerCount+1);
        for (const [i, player] of players.entries()) {
          player.x = (spacing*(i+1))+padding
          player.y = 0;
          player.z = 1+i;
          player.pos = Infinity;
        }
        players.sort((a, b) => (
            a.playerNumber - b.playerNumber
        ));

      } else {
        // Positions for playing screen
        const known = game.state.known;
        players.sort((a, b) => (
            Math.max(known, a.progress) - Math.max(known, b.progress) ||
            b.lastMove - a.lastMove ||
            b.playerNumber - a.playerNumber
        ));
        const tickCount = 81-(game.state.known-1);
        let ticklineWidth = displayWidth;
        if (game.state.player.length > 1) {
          ticklineWidth = displayWidth - (Math.min(this.completeCount, 3)*34);
        }
        const tickSpacing = ticklineWidth / (tickCount-1);
        for (const [i, player] of players.entries()) {
          if (player.progress == 81) {
            player.pos = players.length - i;
          } else {
            player.pos = Infinity;
          }
          const progress = Math.max(player.progress, known) - game.state.known;
          let extra = 0;
          if (players.length > 1 && player.pos <= 3) {
            extra = ((Math.min(this.completeCount, 3) - player.pos) + 1) * 34;
          }
          player.x = padding + (progress*tickSpacing) + extra;
          player.z = 1+i;
        }
        let lastProg = Infinity;
        let lastProgCount = 0;
        for (let i=players.length-1; i>=0; i--) {
          const player = players[i];
          if (player.pos <= 3) {
            player.y = 0;
          } else {
            if (Math.max(known, player.progress) < lastProg){
              lastProgCount = 0;
              lastProg =  Math.max(known, player.progress);
            } else {
              lastProgCount += 1;
            }
            player.y = -(6 *  lastProgCount);
          }
        }
        players.sort((a, b) => (
            a.playerNumber - b.playerNumber
        ));

      }

      // Set styles
      for (const player of players) {
        player.styles = {
          transform: `translate(${player.x}px, ${player.y}px)`,
          zIndex: player.z,
        }
      }
      return players;
    },

    progressTicks(): any {
      const game = this.game as any;
      if (game.state.status == 'open') return [];
      const padding = this.padding;
      const displayWidth = this.windowWidth - (padding*2);
      let ticklineWidth = displayWidth;
      if (game.state.player.length > 1) {
        ticklineWidth = displayWidth - (Math.min(this.completeCount, 3)*34);
      }
      const tickCount = 81-(game.state.known-1);
      const spacing = ticklineWidth / (tickCount-1);
      const ticks = []
      for (let i=0; i<tickCount; i++) {
        const progress = game.state.known + i;
        const x = (spacing*i)+padding;
        let  hasPlayer = false;
        for (const player of this.players) {
          if (i==0 && player.progress<game.state.known) {
            hasPlayer = true;
          } else if (player.progress == progress) {
            hasPlayer = true;
          }
        }
        ticks.push({
          i: i,
          styles: {
            transform: `translateX(${x}px)`,
          },
          hasPlayer: hasPlayer,
        })
      }
      return ticks;
    }
  },
})
</script>

<style scoped>

  .players {
    position: relative; 
    width: 100%;
    height: 45px;
  }

  .player-avatar {
    position: absolute;
    transition: transform 0.6s ease;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.6s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .progress-ticks {
    position: absolute;
    top: 37px;
    left: 0;
    width: 100%;
    height: 5px;
  }

  .progress-ticks > div {
    position: absolute;
    left:-1px;
    transition: transform 0.6s ease;
  }

  .progress-ticks > div::after {
    display: block;
    content: '';
    border-left: 1px solid rgba(var(--ion-color-medium-rgb), 0.7);
    height: 5px;
    position: absolute;
    transition: all 0.6s ease;
  }

  .progress-ticks > div.has-player::after {
    border-color: rgba(var(--ion-color-dark-rgb), 0.9);
    transform: scaleY(2) scaleX(2);
  }

</style>