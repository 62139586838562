
import { defineComponent } from 'vue';
import * as api from '@/api.ts';
import { store } from '@/store';

export default defineComponent({
  name: 'GridCell',
  props: {
    game: api.Game,
    row: Number,
    col: Number,
    enabled: Boolean,
  },
  computed: {
    showHighlights() {
      return store.state.showHighlights
    },
    inputFont() {
      return store.state.inputFont
    },
    inputFontClass() {
      return `input-font-${this.inputFont}`
    },
    inputColor() {
      return store.state.inputColor
    },
    inputColorClass() {
      return `input-color-${this.inputColor}`
    },
    selected() {
      const game: any = this.game;
      const selected = game.state.selected;
      if (selected != undefined){
        if ((selected.row == this.row) && (selected.col == this.col)) {
          return true
        }
      }
      return false;
    },
    alignSelected() {
      if (!this.showHighlights) return false;
      const game: any = this.game;
      const selected = game.state.selected;
      const row = this.row as any;
      const col = this.col as any;
      if (selected != undefined){
        if ((selected.row == row) || (selected.col == col)) {
          return true
        }
        if ((~~(selected.row/3) == ~~(row/3)) && (~~(selected.col/3) == ~~(col/3))) {
          return true
        }
      }
      return false;
    },
    selectedValue(): (number | null) {
      const game: any = this.game;
      const selected = game.state.selected;
      if (this.game && selected != undefined){
        return this.game.state.grid[selected.row][selected.col].number
      }
      return null
    },
    matchSelectedNumber(): boolean {
      if (!this.showHighlights) return false;
      return this.number == this.selectedValue;
    },
    number(): (number | null) {
      const game: any = this.game;
      return game.state.grid[this.row as number][this.col as number].number || null;
    },
    preset() {
      const game: any = this.game;
      if (this.row == undefined || this.col == undefined) return false;
      if (game.state.grid[this.row][this.col].preset) return true;
      return false
    },
    notpreset() {
      return !this.preset;
    },
    // notes(){
    //   const game: any = this.game;
    //   notes = 
    // }
  }
})
